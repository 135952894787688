import { ButtonProps } from "components/Common/Button/Button.tsx";
import { PillProps } from "components/Common/Pill/Pill.tsx";

export enum MediaTypeEnum {
  image = "image",
  video = "video",
  audio = "audio",
  article = "article"
}

export type MediaType = keyof typeof MediaTypeEnum;

export interface CardHeaderMediaProps {
  type: MediaType;
  src: string | { default: string };
  alt?: string;
  duration?: string;
  onMediaClick?: () => void;
}

export interface TitleProps {
  text: string;
  className?: string;
}

export interface DescriptionProps {
  text: string;
  className?: string;
}

export interface AuthorProps {
  name: string;
  image: string | null;
}

export interface CardProps {
  title: TitleProps;
  description: DescriptionProps;
  headerMedia?: CardHeaderMediaProps;
  tags?: PillProps[];
  button?: ButtonProps;
  className?: string;
  author?: AuthorProps;
  openIn?: string;
  viewsAmount?: string;
  url?: string;
}
