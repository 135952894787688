/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import PageWrapper from "hoc/PageWrapper";
import Header from "containers/Common/HeaderContainer";

import animationData from "assets/images/candidate/error.json";
import { Link } from "react-router-dom";
import { store } from "store";
import { fetchStripePortalUrl } from "store/modules/users/actions";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const DisplayUpgradeButton = ({
  t,
  isAdmin,
  showMeteredSubscriptionUnpaidPaige,
  showPrimarySubscriptionUnpaidPage,
  primarySubscriptionId
}) => {
  if (isAdmin && showPrimarySubscriptionUnpaidPage) {
    return (
      <Link
        className="n-button__medium n-bg-purple-100 n-white trial-completed__link"
        to="/plans"
      >
        {t("button.upgrade")}
      </Link>
    );
  }

  if (isAdmin && showMeteredSubscriptionUnpaidPaige) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        className="n-button__medium n-bg-purple-100 n-white trial-completed__link"
        onClick={() => {
          store.dispatch(fetchStripePortalUrl({ primarySubscriptionId, redirect: true, type: "upgrade_plan" }));
        }}
      >
        {t("billing.update")}
      </Link>
    );
  }

  return null;
};

const SubscriptionFailed = ({
  t,
  notifications: {
    is_admin_user: isAdmin = false,
    show_metered_subscription_unpaid_page: showMeteredSubscriptionUnpaidPaige,
    show_primary_subscription_unpaid_page: showPrimarySubscriptionUnpaidPage
  },
  currentUser
}) => {
  const {
    stripe_service_portal_url: stripeServicePortalUrl,
    primary_subscription_id: primarySubscriptionId,
    owner
  } = currentUser;
  const openBeacon = () => {
    if (window.Beacon) {
      window.Beacon("open");
      window.Beacon("navigate", "/ask/message/");
      window.Beacon("prefill", {
        subject: "Subscription failed",
        text: "Hello, my subscription payment has failed - can you please help?"
      });
    }
  };

  return (
    <PageWrapper header={<Header />}>
      <Container className="trial-completed__container">
        <Row>
          <Col>
            <Lottie options={defaultOptions} height={200} width={200} />
            <h1 className="n-font-giant n-text-center trial-completed__header">
              {t("subscriptionFailed.header")}
            </h1>
            <p className="n-font-medium n-text-center trial-completed__text">
              {t("subscriptionFailed.problems")}
            </p>
            <p className="n-font-medium n-text-center trial-completed__text">
              {t(
                isAdmin
                  ? "subscriptionFailed.toContinueAdmin"
                  : "subscriptionFailed.toContinue"
              )}
            </p>

            {!isAdmin && (
              <p className="n-font-medium n-text-center trial-completed__text">
                {t("subscriptionFailed.pleaseContact", {
                  ownerName: owner?.full_name,
                  ownerEmail: owner?.email
                })}
              </p>
            )}

            <DisplayUpgradeButton
              t={t}
              isAdmin={isAdmin}
              showMeteredSubscriptionUnpaidPaige={showMeteredSubscriptionUnpaidPaige}
              showPrimarySubscriptionUnpaidPage={showPrimarySubscriptionUnpaidPage}
              stripeServicePortalUrl={stripeServicePortalUrl}
              primarySubscriptionId={primarySubscriptionId}
            />

            <p className="n-font-small n-text-center trial-completed__support">
              {t("trialPeriod.prefer")}
              <button
                type="button"
                onClick={openBeacon}
                className="n-font-small trial-completed__support"
              >
                {t("trialPeriod.team")}
              </button>
            </p>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

SubscriptionFailed.defaultProps = {
  notifications: { is_admin_user: false },
  currentUser: {}
};

SubscriptionFailed.propTypes = {
  t: PropTypes.func.isRequired,
  notifications: PropTypes.shape({
    is_admin_user: PropTypes.bool,
    show_metered_subscription_unpaid_page: PropTypes.bool,
    show_primary_subscription_unpaid_page: PropTypes.bool
  }),
  currentUser: PropTypes.shape({
    stripe_service_portal_url: PropTypes.string,
    primary_subscription_id: PropTypes.string,
    owner_name: PropTypes.string,
    owner_email: PropTypes.string
  })
};

export default withTranslation()(SubscriptionFailed);
