import JobsTemplateCategories from "components/JobsTemplate/JobTemplateCategories.tsx";
import JobsTemplateHeader from "components/JobsTemplate/JobTemplateHeader.tsx";
import JobsTemplateList from "components/JobsTemplate/JobTemplateList.tsx";
import PageWrapper from "hoc/PageWrapper";
import React, { useEffect, useState } from "react";
import Header from "containers/Common/HeaderContainer";
import { Container } from "react-bootstrap";
import useInterviewTemplatesStore from "store/useTemplateJobStore.ts";
import { getCandidateToken } from "store/modules/сandidates/selectors";
import { connect } from "react-redux";


const JobsTemplatesPage = ({ token }: {token:string}) => {
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const { getTemplates, templates, categories, getCategories } = useInterviewTemplatesStore();


  useEffect(() => {
    getTemplates({});
    getCategories();
  }, [getCategories, getTemplates, token]);

  const handleCategorySelect = (categoryId: string) => {
    setSelectedCategory(categoryId);
    getTemplates({
      category: categoryId,
      page: 1
    });
  };


  return (
    <PageWrapper header={<Header />}>
      <div className="min-h-screen">
        <JobsTemplateHeader />
        <Container>
          <div className="flex mt-5 md:flex-row flex-col">
            <JobsTemplateCategories
              categories={categories}
              onCategorySelect={handleCategorySelect}
              selectedCategory={selectedCategory}
            />
            <div className="flex-1">
              <JobsTemplateList templates={templates} />
            </div>
          </div>
        </Container>
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = (state:any) => ({
  token: getCandidateToken(state)
});

export default connect(mapStateToProps)(JobsTemplatesPage);
