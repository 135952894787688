/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { convertToRaw } from "draft-js";
import PropTypes from "prop-types";
import draftToHtml from "draftjs-to-html";
import cn from "classnames";
import { isAndroid, isIOS } from "react-device-detect";
import Switch from "rc-switch";

import setupConfig from "configs/jobs/setupConfig";

import Input from "components/Common/Input";
import Select from "components/Common/Select";
import TextEditor from "components/Common/TextEditor";
import AddMoreBtn from "components/Common/AddMoreBtn";
import VideoRecordingDesktop from "containers/Jobs/VideoCreate";
import VideoRecordingMobile from "containers/Jobs/VideoCreateMobile";

import {
  updateCurrentStateHandler,
  checkIfFormIsValid,
  prefilledInfo,
  checkSelectValidity,
  encodeSurrogatePairs
} from "mixins/helpers";
import { handleVideoExtenision } from "mixins/helperVideoRecording";
import { replaceAll } from "utils/string";
import { store } from "store";
import { fetchScorecardsOptions } from "store/modules/scorecards/action";
import Message from "components/Common/Message";
import { getJobsErrorWithFields } from "store/modules/jobs/selectors";
import { set } from "lodash";

const Setup = ({
  t,
  setupInfo,
  companiesOptionsForSelect,
  setIsJobSetupValid,
  saveSetupInfo,
  setEditableJobSetup,
  videoError,
  setCurrentStepActive,
  isLoadingCompanies,
  companies,
  isEdit,
  error
}) => {
  const [controls, setControls] = useState(setupConfig);
  const [formIsValid, setFormIsValid] = useState(false);
  const [btnsDisabled, setBtnsDisabled] = useState({});
  const [hasLoaded, setHasLoaded] = useState(false);

  const inputChangedHandler = (event, controlName, type) => {
    const updatedControls = updateCurrentStateHandler(
      event,
      controls,
      controlName,
      type
    );
    const formIsValidCurrent = checkIfFormIsValid(updatedControls);

    setControls(updatedControls);
    setFormIsValid(formIsValidCurrent);
  };


  useEffect(() => {
    let predefinedControls = prefilledInfo(controls, setupInfo) || controls;
    predefinedControls.company.options = companiesOptionsForSelect;

    if (!isEdit) {
      const defaultValue = companies?.filter(a => a.is_default)?.[0];

      if (defaultValue) {
        predefinedControls = updateCurrentStateHandler(
          {
            label: defaultValue.name,
            value: defaultValue.key
          },
          predefinedControls,
          "company",
          "select"
        );
      } else if (companiesOptionsForSelect.length >= 1) {
        predefinedControls = updateCurrentStateHandler(
          companiesOptionsForSelect[0],
          predefinedControls,
          "company",
          "select"
        );
      }
    }

    const formIsValidCurrent = checkIfFormIsValid(predefinedControls);

    setControls(predefinedControls);
    setFormIsValid(formIsValidCurrent);
    setHasLoaded(true);
  }, [companiesOptionsForSelect.length, setupInfo]);


  useEffect(() => {
    store.dispatch(fetchScorecardsOptions());
  }, []);

  const onMenuOpen = controlName => () => {
    const updatedControls = checkSelectValidity(controlName, controls);
    setControls(updatedControls);
  };

  const onSubmitHandler = event => {
    event.preventDefault();

    const descriptionValue = controls.description.value;

    const callback = setup => {
      setIsJobSetupValid(true);
      setEditableJobSetup(setup, () => setCurrentStepActive("questions"));
    };

    const raw = convertToRaw(descriptionValue.getCurrentContent());

    set(raw, "blocks", raw?.blocks?.map(block => ({
      ...block,
      text: encodeSurrogatePairs(block.text)
    })));

    saveSetupInfo({
      id: setupInfo.id,
      title: controls.title.value,
      salary: controls.salary.value,
      company: controls.company.value,
      video: controls.video.value,
      ownVideo: controls.ownVideo.value,
      ownVideoUrl: controls.ownVideoUrl.value,
      isOwn: controls.isOwn.value,
      description: replaceAll(draftToHtml(
        raw
      ), "<br>", "<p></p>"),
      blob: controls.blob.value,
      mediaExtension: controls.blob?.value?.name
        ? handleVideoExtenision(controls.blob.value.name)
        : "webm",
      callback
    });
  };

  const isMediaRecorderSupported = "MediaRecorder" in window;

  const handleSwitch = e => inputChangedHandler(e, "isOwn", "prefilled");
  const changeVideoBlob = blob => inputChangedHandler(blob, "blob", "prefilled");

  const supLabelElement = (
    <span className="label__main">
      <span className="label__text">{t("input.inputLabelRecord")}</span>
      <Switch onChange={handleSwitch} checked={controls.isOwn.value} />
    </span>
  );

  const errorMessagesWithFields = getJobsErrorWithFields(store.getState());
  const isFieldError = error?.includes("Ensure this field");

  const handleErrorMessage = useCallback(() => {
    if (errorMessagesWithFields?.length) {
      const updatedControls = { ...controls };
      errorMessagesWithFields.forEach(({ field, error: errorMessage }) => {
        if (updatedControls[field]?.value?.length > 0) {
          updatedControls[field] = {
            ...updatedControls[field],
            errorMessage: errorMessage?.trim?.(),
            valid: false,
            touched: true
          };
        }
      });

      const formIsValidCurrent = checkIfFormIsValid(updatedControls);

      setControls(updatedControls);
      setFormIsValid(formIsValidCurrent);
    }
  }, [errorMessagesWithFields, controls]);

  useEffect(() => {
    if (hasLoaded) {
      setHasLoaded(false);
      handleErrorMessage();
    }
  }, [errorMessagesWithFields, hasLoaded]);

  return (
    <form className="form-description" onSubmit={onSubmitHandler}>
      <Container>
        <Row>
          <Col md={12}>
            {error && !formIsValid && !isFieldError && (
              <Message
                error
                message={
                  typeof error === "object" ? JSON.stringify(error) : error
                }
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Input
              name="title"
              formElement={controls.title}
              inputChangedHandler={inputChangedHandler}
            />

            <Select
              name="company"
              formElement={controls.company}
              onMenuOpen={onMenuOpen("company")}
              inputChangedHandler={inputChangedHandler}
              isLoading={isLoadingCompanies}
              disabled={isLoadingCompanies}
            />

            <AddMoreBtn link="/companies/create" />
          </Col>

          <Col md={6}>
            <Input
              name="salary"
              formElement={controls.salary}
              inputChangedHandler={inputChangedHandler}
            />

            <Input
              name="video"
              formElement={{
                ...controls.video,
                supLabelElement
              }}
              inputChangedHandler={inputChangedHandler}
              disabled={controls.isOwn.value}
            />
          </Col>

          <Col
            md={12}
            className={`job__video-container ${controls.isOwn.value ? "visible" : ""
              }`}
          >
            {controls.isOwn.value ? (
              <>
                <Row className="job__video">
                  {isIOS || (isAndroid && !isMediaRecorderSupported) ? (
                    <VideoRecordingMobile
                      videoBlob={controls.blob.value}
                      videoLink={controls.ownVideo.value}
                      changeVideoBlob={changeVideoBlob}
                      videoError={videoError}
                    />
                  ) : (
                    <VideoRecordingDesktop
                      videoBlob={controls.blob.value}
                      videoLink={controls.ownVideo.value}
                      changeVideoBlob={changeVideoBlob}
                      videoError={videoError}
                      btnsDisabled={btnsDisabled}
                      setBtnsDisabled={setBtnsDisabled}
                    />
                  )}
                </Row>
              </>
            ) : null}
          </Col>

          <Col md={12}>
            <TextEditor
              name="description"
              type="texteditor"
              formElement={controls.description}
              inputChangedHandler={inputChangedHandler}
              invalid={!controls.description.valid}
              toolbarOptions={["inline", "blockType", "list", "textAlign", "link"]}
            />

            <div className="button__group button__group_center">
              <button
                id="job-next-1-button"
                type="submit"
                className={cn("n-button__medium", "n-bg-purple-100", "n-white")}
                disabled={!formIsValid || btnsDisabled.btnStart}
              >
                {setupInfo.id ? t("button.next") : t("button.save")}
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

Setup.defaultProps = {
  videoError: undefined,
  isLoadingCompanies: undefined,
  companies: undefined,
  isEdit: undefined,
  error: undefined
};

Setup.propTypes = {
  t: PropTypes.func.isRequired,
  setupInfo: PropTypes.shape({
    id: PropTypes.string,
    blob: PropTypes.shape({})
  }).isRequired,
  videoError: PropTypes.shape({}),
  setIsJobSetupValid: PropTypes.func.isRequired,
  companiesOptionsForSelect: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  saveSetupInfo: PropTypes.func.isRequired,
  setEditableJobSetup: PropTypes.func.isRequired,
  setCurrentStepActive: PropTypes.func.isRequired,
  isLoadingCompanies: PropTypes.bool,
  companies: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string
  })),
  isEdit: PropTypes.bool,
  error: PropTypes.string
};

export default withTranslation()(Setup);
