/* eslint-disable camelcase */
import { create } from "zustand";
import { store } from "store";
import { getRequestWithToken } from "../sagas/api";

export interface Category {
  key: string;
  name: string;
}

export interface Template {
  key: string;
  title: string;
  category: Category;
  question_count: number;
  is_featured: boolean;
}

interface TemplatesResponse {
  next: string | null;
  previous: string | null;
  results: Template[];
}

interface FilterParams {
  category?: string;
  query?: string;
  page: number;
}

type InterviewTemplatesStore = {
  templates: Template[];
  categories: Category[];
  isLoading: boolean;
  isCategoriesLoading: boolean;
  isNextPageLoading: boolean;
  error: string | null;
  categoriesError: string | null;
  hasNextPage: boolean;
  currentPage: number;
  filterParams: FilterParams;
  getTemplates: (params?: Partial<FilterParams>) => Promise<void>;
  getCategories: () => Promise<void>;
  resetTemplates: () => void;
  resetCategories: () => void;
  setFilterParams: (params: Partial<FilterParams>) => void;
  resetFilterParams: () => void;
}

const initialFilterParams: FilterParams = {
  category: undefined,
  query: undefined,
  page: 1
};

const initialState = {
  templates: [],
  categories: [],
  isLoading: false,
  isCategoriesLoading: false,
  error: null,
  categoriesError: null,
  hasNextPage: false,
  currentPage: 1,
  filterParams: initialFilterParams,
  isNextPageLoading: false
};

const useInterviewTemplatesStore = create<InterviewTemplatesStore>((set, get) => ({
  ...initialState,

  setFilterParams: (params: Partial<FilterParams>) => {
    const currentFilters = get().filterParams;
    const hasChanges = Object.entries(params).some(
      ([key, value]) => currentFilters[key as keyof FilterParams] !== value
    );

    if (hasChanges) {
      set(state => ({
        filterParams: {
          ...state.filterParams,
          ...params
        }
      }));
    }
  },

  resetFilterParams: () => {
    set(state => (state.filterParams !== initialFilterParams
      ? { filterParams: initialFilterParams }
      : state));
  },

  getTemplates: async (params?: Partial<FilterParams>) => {
    try {
      const { token } = store.getState()?.auth?.data ?? {};
      if (!token) return;

      if (params) {
        get().setFilterParams(params);
      }

      const currentFilters = get().filterParams;
      const { page = 1 } = currentFilters;

      // Set appropriate loading state based on page number
      set({
        ...(page === 1 ? { isLoading: true } : { isNextPageLoading: true }),
        error: null
      });

      const queryParams = new URLSearchParams();

      if (page > 1) {
        queryParams.append("page", page.toString());
      }
      if (currentFilters.category) {
        queryParams.append("category", currentFilters.category);
      }
      if (currentFilters.query) {
        queryParams.append("search", currentFilters.query);
      }

      const queryString = queryParams.toString();
      const url = `/api/interview-templates${queryString ? `?${queryString}` : ""}`;

      const response = await getRequestWithToken({
        url,
        token
      }) as { data: TemplatesResponse };

      const { results } = response.data;

      set(state => ({
        templates: page === 1 ? results : [...state.templates, ...results],
        hasNextPage: results?.length === 20,
        currentPage: page,
        isLoading: false,
        isNextPageLoading: false,
        error: null
      }));
    } catch (err: any) {
      console.error("Error fetching templates:", err);
      set({
        isLoading: false,
        isNextPageLoading: false,
        error: err.message
      });
    }
  },

  getCategories: async () => {
    const { token } = store.getState()?.auth?.data ?? {};
    if (!token) return;

    try {
      set({ isCategoriesLoading: true, categoriesError: null });

      const response = await getRequestWithToken({
        url: "/api/interview-templates/categories",
        token
      }) as { data: Category[] };

      set({
        categories: response.data,
        isCategoriesLoading: false
      });
    } catch (err: any) {
      console.error("Error fetching categories:", err);
      set({
        isCategoriesLoading: false,
        categoriesError: err.message
      });
    }
  },

  resetTemplates: () => {
    const currentState = get();
    const needsReset = currentState.templates.length > 0 ||
                      currentState.error !== null ||
                      currentState.hasNextPage ||
                      currentState.currentPage !== 1;

    if (needsReset) {
      set({
        templates: [],
        error: null,
        hasNextPage: false,
        currentPage: 1,
        filterParams: initialFilterParams
      });
    }
  },

  resetCategories: () => {
    const currentState = get();
    const needsReset = currentState.categories.length > 0 ||
                      currentState.categoriesError !== null;

    if (needsReset) {
      set({
        categories: [],
        isCategoriesLoading: false,
        categoriesError: null
      });
    }
  }
}));

export default useInterviewTemplatesStore;
