import React from "react";

export interface PillProps {
  title: string;
  backgroundColor?: string;
  fontColor?: string;
}

const Pill = ({ title, backgroundColor = "#E2E8F0", fontColor = "#1A202C" } : PillProps) => (
  <span
    className="px-3 py-1 rounded-full text-sm font-medium"
    style={{
      backgroundColor,
      color: fontColor
    }}
  >
    {title}
  </span>
);

export default Pill;
