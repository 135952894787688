import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ALL_IDV_REJECTED_REASON, DBS_RTW_TYPE, DBS_TYPE, IDV_ABORTED, IDV_ACCEPTED, IDVStatusChip } from "configs/jobs/constants";
import { get, isNull } from "lodash";

const DBSCheck = ({
  t,
  idvCheckData,
  isIdvSkipped
}) => {
  const isDBSCheck = [DBS_TYPE, DBS_RTW_TYPE].includes(idvCheckData?.check_type);

  const isCheckAborted = idvCheckData?.dbs?.status === IDV_ABORTED;

  const isDocumentProcessing =
    useMemo(() => (isNull(get(idvCheckData, "documents[0].document_type", null)) ||
    isNull(get(idvCheckData, "documents[0].images_id")) ||
    !idvCheckData?.documents) && !isIdvSkipped && !isCheckAborted,
    [idvCheckData, isCheckAborted, isIdvSkipped]);

  if (!isDBSCheck || isDocumentProcessing) return null;

  return (
    <div className="candidateanswer__section__response-idv__response-item">
      <div className="candidateanswer__section__response-idv__response-item__row title">
        <div className="candidateanswer__section__response-idv__response-item-title">
          {t(`job.candidate.overview.idv.dbs_check.${isCheckAborted ? "aborted" : "title"}`)}
        </div>
        {IDVStatusChip[idvCheckData?.dbs?.status]}
      </div>

      {
        idvCheckData?.overall_status === IDV_ACCEPTED ? (
          <div className="candidateanswer__section__response-idv__response-item__row">
            <p className="w-3/4">
              {t(
                "job.candidate.overview.idv.answer.levelOfConfidence"
              )}
            </p>
          </div>
        ) : null
      }

      {
        isCheckAborted ? (
          <div className="candidateanswer__section__response-idv__response-item__row">
            <p className="w-3/4">
              {ALL_IDV_REJECTED_REASON?.includes(idvCheckData?.dbs?.rejection_reason)
                ? t(`job.candidate.overview.idv.rejection_reason.${idvCheckData?.dbs?.rejection_reason}`)
                : idvCheckData?.dbs?.rejection_reason}
            </p>
          </div>
        ) : null
      }
    </div>
  );
};

export default withTranslation()(DBSCheck);

DBSCheck.defaultProps = {
  idvCheckData: {},
  isIdvSkipped: false
};

DBSCheck.propTypes = {
  t: PropTypes.func.isRequired,
  idvCheckData: PropTypes.shape({
    documents: PropTypes.shape([]),
    dbs: PropTypes.shape({
      status: PropTypes.string
    }),
    check_type: PropTypes.string,
    overall_status: PropTypes.string
  }),
  isIdvSkipped: PropTypes.bool
};
