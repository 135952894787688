/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";

import { Check, Close, Questionmark } from "mixins/svgIcons";
import AnswerHeader from "components/Common/AnswerHeader";

import "./styles.scss";
import {
  ALL_IDV_REJECTED_REASON,
  DBS_RTW_TYPE,
  DBS_TYPE,
  IDV_ABORTED,
  IDV_OMITTED,
  IDV_SKIPPED,
  IDV_TYPE,
  RTW_COUNTRIES,
  RTW_TYPE
} from "configs/jobs/constants";
import _, { get, isNull } from "lodash";
import { DATE_FORMATS } from "configs/dates";
import moment from "moment";
import { copyToClipboard } from "mixins/helpers";
import { ClipLoader } from "react-spinners";
import { getRequestWithToken } from "sagas/api";
import { connect } from "react-redux";
import { getToken } from "store/modules/auth/selectors";
import {
  getCandidateIdvResponse,
  getIsJobCandidateLoading
} from "store/modules/jobs/selectors";
import downloadFile from "utils/downloadFile";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import { isPublicInfoLoading } from "store/modules/public/selectors";
import CopyButton from "../CopyButton";

const icons = {
  accepted: <Check height={15} width={15} />,
  inconclusive: <Questionmark />,
  Omitted: <Questionmark />,
  skipped: <Questionmark />,
  rejected: <Close fill="#E83B3B" height="15" width="15" />
};

const CandidateIdvCheckItem = ({
  t,
  question,
  questionNumber,
  size,
  textSize,
  questionTextProps,
  thinkingTime,
  spentTime,
  idvCheckData,
  currentJob,
  candidateInfo,
  currentQuestion,
  token,
  idvResponse,
  isJobCandidateLoading,
  isShowcaseLoading
}) => {
  const ref = useRef(null);
  const [copied, setCopied] = useState("");

  const pdfFileName = `${candidateInfo?.name} Digital Identity Check - ${currentJob?.title}.pdf`;

  const handleCopy = () => {
    const regex = /-/gi;
    const shareCode = idvCheckData?.right_to_work?.share_code?.replaceAll(
      regex,
      " "
    );
    setCopied(idvCheckData?.right_to_work?.share_code);
    copyToClipboard(shareCode);

    setTimeout(() => {
      setCopied("");
    }, 3000);
  };

  const handleSelectText = () => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.setStartBefore(ref.current);
    range.setEndAfter(ref.current);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  countries.registerLocale(english);
  const alpha2code = countries.alpha3ToAlpha2(
    idvCheckData?.document?.issuing_country,
    "en"
  );
  const countryName = countries.getName(alpha2code, "en");
  const date = moment(idvCheckData?.identity?.created_at).format(
    DATE_FORMATS.SPACE_SEPARATOR_MONTH_FIRST
  );

  const questionText =
    question?.answer_type === IDV_TYPE
      ? t("job.candidate.overview.idv.answer.thumbnail.title")
      : t(
          `job.candidate.overview.idv.answer.thumbnail.${question?.answer_type}`
      );

  const isIdvSkipped =
    idvCheckData?.is_idv_skipped ||
    idvCheckData?.overall_status === IDV_SKIPPED;
  const isOverAllStatusSkipped = idvCheckData?.overall_status === IDV_SKIPPED;

  const identityStatus = isIdvSkipped
    ? IDV_OMITTED
    : idvCheckData?.identity?.status;

  const isCheckAborted = [
    idvCheckData?.dbs?.status,
    idvCheckData?.right_to_work?.status
  ].includes(IDV_ABORTED);

  const isDocumentProcessing =
    (isNull(get(idvCheckData, "documents[0].document_type", null)) ||
      isNull(get(idvCheckData, "documents[0].images_id")) ||
      !idvCheckData?.documents) &&
    !isIdvSkipped && !isCheckAborted;

  const isIdentityProcessing =
    (isNull(idvCheckData?.identity?.image_id) || !idvCheckData?.identity) &&
    !isIdvSkipped;

  const shouldShowShareCode =
    [RTW_TYPE, DBS_RTW_TYPE].includes(idvCheckData?.represent_as) &&
    !RTW_COUNTRIES.includes(currentQuestion?.idv_citizenship_iso_code) &&
    !isOverAllStatusSkipped;

  const getIdvStatus = document => {
    if (isIdvSkipped) return IDV_OMITTED;
    return document.status;
  };

  const handleDownloadClick = async (type, index) => {
    const response = await getRequestWithToken({
      url: `/api/idv/${type}/media/${
        type === "document"
          ? idvResponse?.documents[index]?.images_id[0]
          : idvResponse?.identity?.image_id
      }/`,
      token,
      responseType: "arraybuffer"
    });

    if (response.status === 200) {
      const buffer = new Uint8Array(response.data);
      const blob = new Blob([buffer], { type: "image/jpeg" });
      const imgUrl = window.URL.createObjectURL(blob);

      downloadFile(imgUrl);
    }
  };

  if (idvCheckData?.error && !idvCheckData?.error?.includes("404")) {
    return (
      <div className="video__preview-item">
        <AnswerHeader
          questionNumber={questionNumber}
          thinkingTime={thinkingTime}
          spentTime={spentTime}
          textSize={textSize}
          questionTextProps={questionTextProps}
          questionText={questionText}
          currentQuestion={currentQuestion}
        />

        <SkeletonWrapper
          skeletonProps={{
            count: 1,
            enableAnimation: true,
            height: "30vh",
            borderRadius: 20
          }}
          skeletonWrapperProps={{
            style: {
              display: "block",
              lineHeight: 1.5,
              marginBottom: "0.5rem",
              width: "100%"
            }
          }}
          isLoading={isJobCandidateLoading || isShowcaseLoading}
        >
          <div className="text__answer-scroll" onDoubleClick={handleSelectText}>
            <div className={`text__answer ${size}`} ref={ref}>
              <div className="idv-check__container">
                <div className="idv-check__container-text-wrapper">
                  {idvCheckData?.error}
                </div>
              </div>
            </div>
          </div>
        </SkeletonWrapper>
      </div>
    );
  }

  return (
    <div className="video__preview-item">
      <AnswerHeader
        questionNumber={questionNumber}
        thinkingTime={thinkingTime}
        spentTime={spentTime}
        textSize={textSize}
        questionTextProps={questionTextProps}
        questionText={questionText}
      />

      <SkeletonWrapper
        skeletonProps={{
          count: 1,
          enableAnimation: true,
          height: "30vh",
          borderRadius: 20
        }}
        skeletonWrapperProps={{
          style: {
            display: "block",
            lineHeight: 1.5,
            marginBottom: "0.5rem",
            width: "100%"
          }
        }}
        isLoading={isJobCandidateLoading || isShowcaseLoading}
      >
        <div className="text__answer-scroll" onDoubleClick={handleSelectText}>
          <div className={`text__answer ${size}`} ref={ref}>
            <div className="idv-check__container">
              {isDocumentProcessing ? (
                <div className="idv-check__container-text-wrapper">
                  <ClipLoader color="#BBC2C9" size={18} />
                  <div className="idv-check__container-title">
                    <div className="idv-check__container-title-wrapper">
                      {t("job.candidate.overview.idv.document.processingTitle")}
                      <strong>
                        {t("job.candidate.overview.idv.document.processing")}
                      </strong>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 15 }}
                >
                  {idvCheckData?.documents?.map((document, index) => (
                    <div
                      className="idv-check__container-text-wrapper"
                      key={`document-${String(index)}`}
                    >
                      {!isDocumentProcessing ? (
                        icons[document.status]
                      ) : (
                        <ClipLoader color="#BBC2C9" size={18} />
                      )}

                      <div className="idv-check__container-title-wrapper">
                        <div className="idv-check__container-title">
                          {!isDocumentProcessing ? (
                            `${
                              document?.document_type === "n/a"
                                ? t(
                                  "job.candidate.overview.idv.document.skippedTitle"
                                )
                                : document?.document_type ??
                                  t(
                                    "job.candidate.overview.idv.document.skippedTitle"
                                  )
                            }: ${_.capitalize(getIdvStatus(document) ?? "")}`
                          ) : (
                            <div>
                              {t(
                                "job.candidate.overview.idv.document.processingTitle"
                              )}
                              <strong>
                                {t(
                                  "job.candidate.overview.idv.document.processing"
                                )}
                              </strong>
                            </div>
                          )}
                        </div>

                        {Boolean(document?.rejection_reason) && (
                          <p>
                            {ALL_IDV_REJECTED_REASON?.includes(
                              document?.rejection_reason
                            )
                              ? t(
                                  `job.candidate.overview.idv.rejection_reason.${document?.rejection_reason}`
                              )
                              : document?.rejection_reason}
                          </p>
                        )}

                        {countries.getName(
                          countries.alpha3ToAlpha2(
                            document?.issuing_country,
                            "en"
                          ),
                          "en"
                        ) && (
                          <p>
                            {countries.getName(
                              countries.alpha3ToAlpha2(
                                document?.issuing_country,
                                "en"
                              ),
                              "en"
                            )}
                          </p>
                        )}

                        {document.images_id && !isDocumentProcessing ? (
                          <p
                            className="link_url"
                            onClick={() =>
                              handleDownloadClick("document", index)
                            }
                          >
                            {t("job.candidate.overview.idv.media")}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="idv-check__container">
              <div className="idv-check__container-text-wrapper">
                {!isIdentityProcessing ? (
                  icons[identityStatus]
                ) : (
                  <ClipLoader
                    color="#BBC2C9"
                    size={18}
                    margin={1}
                    loading
                    style={{
                      marginRight: 5
                    }}
                  />
                )}
                <div className="idv-check__container-title-wrapper">
                  <div className="idv-check__container-title">
                    {!isIdentityProcessing ? (
                      t("job.candidate.overview.idv.answer.identity", {
                        status: _.capitalize(identityStatus ?? "")
                      })
                    ) : (
                      <div>
                        {t("job.candidate.overview.idv.check.processingTitle")}
                        <strong>
                          {t("job.candidate.overview.idv.check.processing")}
                        </strong>
                      </div>
                    )}
                  </div>

                  {Boolean(idvCheckData?.identity?.rejection_reason) && (
                    <p>
                      {ALL_IDV_REJECTED_REASON?.includes(
                        idvCheckData?.identity?.rejection_reason
                      )
                        ? t(
                            `job.candidate.overview.idv.rejection_reason.${idvCheckData?.identity?.rejection_reason}`
                        )
                        : idvCheckData?.identity?.rejection_reason}
                    </p>
                  )}

                  {!isIdentityProcessing && countryName && date ? (
                    <p>
                      {`${t(
                        "job.candidate.overview.idv.check.subtitle"
                      )} (${countryName} ${date})`}
                    </p>
                  ) : null}
                  {idvCheckData?.identityImage && !isIdentityProcessing ? (
                    <p
                      className="link_url"
                      onClick={() => handleDownloadClick("identity")}
                    >
                      {t("job.candidate.overview.idv.media")}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            {(idvCheckData?.check_type === "rtw" ||
              idvCheckData?.represent_as === "rtw" ||
              idvCheckData?.check_type === "dbs_rtw" ||
              idvCheckData?.represent_as === "dbs_rtw") &&
              RTW_COUNTRIES.includes(
                question.idv_country_of_employment_iso_code
              ) ? (
                <div className="idv-check__container">
                  <div className="idv-check__container-text-wrapper">
                    {icons[idvCheckData?.right_to_work?.status]}
                    <div className="idv-check__container-title-wrapper">
                      <p className="idv-check__container-title">
                        {t("job.candidate.overview.idv.answer.rtw.status", {
                          status: _.capitalize(idvCheckData?.right_to_work?.status ?? "")
                        })}
                      </p>
                      <p>{`${t("job.candidate.overview.idv.answer.rtw.description")}`}</p>
                    </div>
                  </div>
                </div>
              ) : null}
            {idvCheckData?.check_type?.includes(DBS_TYPE) ? (
              <div className="idv-check__container">
                <div className="idv-check__container-text-wrapper">
                  {!isDocumentProcessing ? (
                    icons[idvCheckData?.dbs?.status]
                  ) : (
                    <ClipLoader color="#BBC2C9" size={18} />
                  )}
                  <div
                    style={{
                      marginLeft: Object.keys(icons).includes(
                        idvCheckData?.dbs?.status
                      )
                        ? 15
                        : 30
                    }}
                  >
                    <p className="idv-check__container-title">
                      {t("job.candidate.overview.idv.answer.idvDbs")}
                      {" "}
                      {_.capitalize(idvCheckData?.dbs?.status)}
                    </p>
                    {idvResponse?.overall_status === "accepted" && (
                      <p>
                        {`${t(
                          "job.candidate.overview.idv.answer.levelOfConfidence"
                        )}`}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {
              // eslint-disable-next-line no-nested-ternary, max-len
              shouldShowShareCode ? (
                <div className="idv-check__container">
                  <div className="idv-check__container-text-wrapper">
                    {icons[idvCheckData?.right_to_work?.status]}
                    <div className="idv-check__container-title-wrapper">
                      <p className="idv-check__container-title">
                        {t(
                          "job.candidate.overview.idv.answer.rtw.shareCode.required"
                        )}
                      </p>
                      {idvCheckData?.right_to_work?.share_code_skipped ? (
                        <p>
                          {`${t(
                            "job.candidate.overview.idv.answer.rtw.shareCode.notProvided"
                          )}`}
                        </p>
                      ) : (
                        <p>
                          {`${t(
                            "job.candidate.overview.idv.answer.rtw.shareCode.code",
                            { code: idvCheckData?.right_to_work?.share_code }
                          )}`}
                          <CopyButton
                            isCopied={
                              copied === idvCheckData?.right_to_work?.share_code
                            }
                            handleCopy={() => handleCopy()}
                          />
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ) : null
            }
            {idvCheckData?.pdfUrl ? (
              <div className="idv-check__pdf">
                <p>
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={idvCheckData.pdfUrl}
                    download={pdfFileName}
                  >
                    {" "}
                    Download PDF report
                    {" "}
                  </a>
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </SkeletonWrapper>
    </div>
  );
};

CandidateIdvCheckItem.defaultProps = {
  history: null,
  question: {},
  questionNumber: "",
  size: "",
  textSize: "",
  answer: {},
  questionTextProps: null,
  memorizedCompanyInfo: {},
  thinkingTime: 0,
  spentTime: 0,
  candidateInfo: {},
  currentJob: undefined
};

CandidateIdvCheckItem.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    length: PropTypes.number,
    push: PropTypes.func
  }),
  question: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    text: PropTypes.string,
    key: PropTypes.string,
    idv_country_of_employment_iso_code: PropTypes.string
  }),
  questionNumber: PropTypes.number,
  size: PropTypes.string,
  textSize: PropTypes.string,
  answer: PropTypes.shape({
    html_text: PropTypes.string,
    text: PropTypes.string
  }),
  questionTextProps: PropTypes.shape({}),
  memorizedCompanyInfo: PropTypes.shape({
    button_color: PropTypes.string
  }),
  thinkingTime: PropTypes.number,
  spentTime: PropTypes.number,
  idvCheckData: PropTypes.shape({
    overall_status: PropTypes.string,
    error: PropTypes.string,
    length: PropTypes.number,
    document_type: PropTypes.string,
    issuing_country: PropTypes.string,
    share_code: PropTypes.string,
    share_code_skipped: PropTypes.bool,
    status: PropTypes.string,
    document: PropTypes.shape({
      status: PropTypes.string,
      issuing_country: PropTypes.string,
      document_type: PropTypes.string,
      image_id: PropTypes.string
    }),
    identity: PropTypes.shape({
      status: PropTypes.string,
      created_at: PropTypes.string,
      image_id: PropTypes.string
    }),
    right_to_work: PropTypes.shape({
      status: PropTypes.string,
      share_code: PropTypes.string,
      share_code_skipped: PropTypes.bool,
      requirements_met: PropTypes.string
    }),
    represent_as: PropTypes.string,
    check_type: PropTypes.string,
    documentImage: PropTypes.string,
    identityImage: PropTypes.string,
    pdfUrl: PropTypes.string,
    is_idv_skipped: PropTypes.bool
  }).isRequired,
  candidateInfo: PropTypes.shape({
    key: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    availabilities: PropTypes.arrayOf(PropTypes.string),
    utc_minutes_delta: PropTypes.number,
    status: PropTypes.string,
    is_locked: PropTypes.bool,
    answers_days_lifetime: PropTypes.number
  }),
  currentJob: PropTypes.shape({
    title: PropTypes.string
  }),
  currentQuestion: PropTypes.shape({
    question: PropTypes.shape({
      html_text: PropTypes.string
    })
  }).isRequired,
  idvResponse: PropTypes.shape({
    document: PropTypes.shape({
      image_id: PropTypes.string
    })
  }).isRequired,
  token: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  token: getToken(state),
  idvResponse: getCandidateIdvResponse(state),
  isJobCandidateLoading: getIsJobCandidateLoading(state),
  isShowcaseLoading: isPublicInfoLoading(state)
});

export default connect(mapStateToProps)(
  withTranslation()(CandidateIdvCheckItem)
);
