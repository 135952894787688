/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { call, put, select, takeEvery, delay } from "redux-saga/effects";
import {
  deleteScorecard,
  duplicateScorecard,
  duplicateScorecardError,
  duplicateScorecardSuccess,
  fetchCandidateScoreByCardId,
  fetchCandidateScoreByCardIdResponse,
  fetchCandidateScores,
  fetchCandidateScoresResponse,
  fetchInterviewCardScorecard,
  fetchInterviewCardScorecardResponse,
  fetchLatestScorecards,
  fetchLatestScorecardsResponse,
  fetchScoreCardDetails,
  fetchScoreCardDetailsError,
  fetchScoreCardDetailsLoading,
  fetchScoreCardDetailsResponse,
  fetchScorecards,
  fetchScorecardsError,
  fetchScorecardsOptions,
  fetchScorecardsOptionsError,
  fetchScorecardsOptionsLoading,
  fetchScorecardsOptionsResponse,
  fetchScorecardsResponse,
  fetchCriteriaTitleSuggestions,
  saveScoreCard,
  saveScoreCardError,
  saveScoreCardLoading,
  setCandidateScoreByCardIdLoading,
  setDeleteScorecardsLoading,
  setIsUpdatingScoreLoading,
  setLatestScorecardsLibraryLoading,
  setScorecardsIsLoading,
  setCriteriaTitleSuggestions,
  updateCandidateScoreCardResponse,
  updateInterviewScore,
  fetchCriteriaDescriptionSuggestions,
  setCriteriaDescriptionSuggestions
} from "store/modules/scorecards/action";
import { getCurrentUserId } from "store/modules/users/selectors";
import { getToken } from "store/modules/auth/selectors";
import {
  getJobCurrent,
  getUserScoreDraftKey
} from "store/modules/jobs/selectors";
import { capitalize } from "lodash";
import { logErrors } from "mixins/helperLogging";
import { history } from "store";
import {
  deleteRequestWithToken,
  getRequestWithToken,
  patchRequestWithToken,
  postRequestWithToken
} from "./api";
import { fetchCandidateHistoryWorker, fetchUserCurrentJobWorker } from "./jobs";

export function* fetchScorecardLibraryWatcher({ payload }) {
  try {
    yield put(duplicateScorecardSuccess(null));
    yield put(duplicateScorecardError(null));
    yield put(setScorecardsIsLoading(true));
    const agencyKey = yield select(getCurrentUserId);
    const token = yield select(getToken);

    const { data, status } = yield call(getRequestWithToken, {
      url: `/api/scorecards/agency/${agencyKey}/?ordering=${payload?.sort ??
        "title"}&search=${payload?.filter ?? ""}`,
      token
    });
    if (status === 200) {
      yield put(fetchScorecardsResponse(data));
    }
  } catch (error) {
    yield put(fetchScorecardsError(error));
  } finally {
    yield put(setScorecardsIsLoading(false));
  }
}

export function* fetchLatestScorecardsLibrariesWorker({
  payload: { next } = {}
}) {
  try {
    yield put(setLatestScorecardsLibraryLoading(true));
    const token = yield select(getToken);

    if (next) {
      // eslint-disable-next-line no-useless-escape
      const url = `/${next.match(/\/\/[^\/]+\/([^\.]+)/)[1]}`;
      const response = yield getRequestWithToken({
        url,
        token
      });
      yield put(fetchLatestScorecardsResponse(response.data));
    }
  } catch (error) {
    //
  } finally {
    yield put(setLatestScorecardsLibraryLoading(false));
  }
}

export function* deleteScorecardWorker({ payload }) {
  try {
    yield put(setDeleteScorecardsLoading(true));
    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    // eslint-disable-next-line no-useless-escape
    yield deleteRequestWithToken({
      url: `/api/scorecards/agency/${agencyKey}/card/${payload}/`,
      token
    });
    yield call(fetchScorecardLibraryWatcher, {});
  } catch (error) {
    //
  } finally {
    yield put(setDeleteScorecardsLoading(false));
  }
}

export function* duplicateScorecardWorker({ payload: { key, title } }) {
  try {
    yield put(setLatestScorecardsLibraryLoading(true));
    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);
    // eslint-disable-next-line no-useless-escape
    const { status, data } = yield call(postRequestWithToken, {
      url: `/api/scorecards/agency/${agencyKey}/card/${key}/duplicate/`,
      token,
      payload: {
        agencyKey,
        key
      }
    });

    if ([200, 201].includes(status)) {
      yield put(
        duplicateScorecardSuccess(
          `Scorecard ${data.title} was successfully added.`
        )
      );
      yield delay(3000);
      yield put(duplicateScorecardSuccess(null));
      yield call(fetchScorecardLibraryWatcher, {});
    }
  } catch (error) {
    yield put(
      duplicateScorecardError(
        error.response?.data?.detail ||
          `Unable to duplicate ${title} scorecard.`
      )
    );
  }
}

export function* fetchCriteriaTitleSuggestionWorker({
  payload: { keyword, cancelToken }
}) {
  try {
    if (!keyword || !keyword?.length || keyword === "") {
      return yield put(setCriteriaTitleSuggestions([]));
    }

    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    const response = yield getRequestWithToken({
      url: `/api/scorecards/agency/${agencyKey}/criteria/text/autocomplete/?search=${keyword}`,
      token,
      cancelTokenSource: cancelToken
    });

    return yield put(setCriteriaTitleSuggestions(response.data));
  } catch (error) {
    if (error?.message !== "Operation canceled due to new request.") {
      console.error("Could not get suggestions", error);
      yield put(setCriteriaDescriptionSuggestions([]));
    }
  }
}

export function* fetchCriteriaDescriptionSuggestionWorker({
  payload: { keyword, cancelToken }
}) {
  try {
    if (!keyword || !keyword?.length || keyword === "") {
      return yield put(setCriteriaDescriptionSuggestions([]));
    }

    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    const response = yield getRequestWithToken({
      url: `/api/scorecards/agency/${agencyKey}/criteria/description/autocomplete/?search=${keyword}`,
      token,
      cancelTokenSource: cancelToken
    });

    return yield put(setCriteriaDescriptionSuggestions(response.data));
  } catch (error) {
    if (error?.message !== "Operation canceled due to new request.") {
      console.error("Could not get suggestions", error);
      yield put(setCriteriaDescriptionSuggestions([]));
    }
  }
}

export function* saveScorecardWorker({
  payload: {
    isUpdate = false,
    cardKey = undefined,
    callback,
    finalCallback,
    ...payload
  }
}) {
  try {
    yield put(saveScoreCardLoading(true));
    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);
    let response;

    if (isUpdate) {
      response = yield call(patchRequestWithToken, {
        url: `/api/scorecards/agency/${agencyKey}/card/${cardKey}/`,
        token,
        payload
      });
    } else {
      response = yield call(postRequestWithToken, {
        url: `/api/scorecards/agency/${agencyKey}/`,
        token,
        payload
      });
    }

    if (typeof callback === "function") yield call(callback, { response });
  } catch (error) {
    // handle error messages:
    const { status, data } = error?.response || {};

    let errorText =
      "There is a problem with saving the scorecard. Recheck all info and then save";

    if (status === 400) {
      const fieldErrorsKeys = Object.keys(data);

      const fieldErrorsText = fieldErrorsKeys
        .map(errorKey => {
          const currentErroMessage = data?.[errorKey];
          const hasInnerFields = Object.keys(data[errorKey]).length > 0;

          if (hasInnerFields && errorKey === "criteria") {
            const specifiFieldsKeys = Object.keys(data[errorKey]);

            const listOfSpecificFields = specifiFieldsKeys
            // eslint-disable-next-line array-callback-return
              ?.map(number => {
                const fieldParams = Object.entries(data[errorKey][number]);

                const isDescriptionOrTitle = Object.keys(fieldParams).length > 0;
                const errText = isDescriptionOrTitle ? `${fieldParams?.[0]?.[0]},${Object.values(fieldParams?.[0]?.[1])}` : fieldParams[0];

                if (fieldParams.length) {
                  const fieldErrorText = `${capitalize(errorKey)} #${Number(
                    number
                  ) + 1}: ${errText}`;

                  return fieldErrorText
                    .replace(",", " - ")
                    .replaceAll("text_markdown", "Criteria title")
                    .replaceAll("text", "Criteria title");
                }
              })
              .filter(item => typeof item !== "undefined");

            return listOfSpecificFields;
          }

          return `${capitalize(
            errorKey
          )}: ${currentErroMessage?.toString()}\n\n`;
        })
        .join(" ");

      errorText = fieldErrorsText
        .replaceAll("text", "Criteria title")
        .replaceAll("text_markdown", "Criteria title")
        .replaceAll("Non_field_errors: ", "")
        .replaceAll(".,", ".\n\n");
    }

    yield put(saveScoreCardError(errorText));
    const userId = yield select(getCurrentUserId);

    logErrors({ error, context: { userId, type: "recruiter" } });
  } finally {
    if (typeof finalCallback === "function") yield call(finalCallback);
    yield put(saveScoreCardLoading(false));
  }
}

export function* fetchScorecardDetailsWorker({
  payload: { cardKey, callback }
}) {
  try {
    yield put(fetchScoreCardDetailsLoading(true));
    const agencyKey = yield select(getCurrentUserId);
    const token = yield select(getToken);

    const { data, status } = yield call(getRequestWithToken, {
      url: `/api/scorecards/agency/${agencyKey}/card/${cardKey}/`,
      token
    });

    if (status === 200) {
      yield put(fetchScoreCardDetailsResponse(data));

      if (typeof callback === "function") {
        yield call(callback, { response: data });
      }
    }
  } catch (error) {
    console.log({ error });

    if (error?.response?.status === 404) return history.push("/page-not-found");
    yield put(fetchScoreCardDetailsError(error?.message));
  } finally {
    yield put(fetchScoreCardDetailsLoading(false));
  }
}

export function* fetchScorecardsOptionsWorker() {
  try {
    yield put(fetchScorecardsOptionsResponse({}));
    yield put(fetchScorecardsOptionsLoading(true));
    const agencyKey = yield select(getCurrentUserId);
    const token = yield select(getToken);

    const { data, status } = yield call(getRequestWithToken, {
      url: `/api/scorecards/agency/${agencyKey}/interview/options/?page_size=50`,
      token
    });

    if (status === 200) {
      yield put(fetchScorecardsOptionsResponse(data));
    }
  } catch (error) {
    console.log({ error });
    yield put(fetchScorecardsOptionsError(error?.message));
  } finally {
    yield put(fetchScorecardsOptionsLoading(false));
  }
}

export function* fetchCandidateScoresWorker({ payload }) {
  try {
    const token = yield select(getToken);
    yield put(setCandidateScoreByCardIdLoading(true));

    const { data, status } = yield call(getRequestWithToken, {
      url: `/api/scorecards/candidate/${payload?.userId}/scores/`,
      token
    });

    if (status === 200) {
      yield put(fetchCandidateScoresResponse(data));
    } else {
      yield put(fetchCandidateScoresResponse({}));
    }
  } catch (err) {
    //
  } finally {
    yield put(setCandidateScoreByCardIdLoading(false));
  }
}
export function* fetchCandidateScoreByCardIdWorker({
  payload: { draftKey = "" }
}) {
  try {
    if (draftKey) {
      yield put(setCandidateScoreByCardIdLoading(true));
      const token = yield select(getToken);
      const draftKeyFromStore = yield select(getUserScoreDraftKey) ?? draftKey;

      const { data, status } = yield call(getRequestWithToken, {
        url: `/api/scorecards/score/${draftKeyFromStore}/`,
        token
      });

      if (status === 200) {
        yield put(fetchCandidateScoreByCardIdResponse(data));
      }
    } else {
      yield put(fetchCandidateScoreByCardIdResponse({}));
    }
  } catch (error) {
    yield put(fetchScorecardsOptionsError(error?.message));
  } finally {
    yield put(setCandidateScoreByCardIdLoading(false));
  }
}
export function* fetchInterviewCardScorecardWorker() {
  try {
    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);
    const job = yield select(getJobCurrent);

    const { data, status } = yield call(getRequestWithToken, {
      url: `/api/scorecards/agency/${agencyKey}/card/${job?.scorecard?.key}/`,
      token
    });

    if (status === 200) {
      yield put(fetchInterviewCardScorecardResponse(data));
    }
  } catch (error) {
    console.log({ error });
    yield put(fetchScorecardsOptionsError(error?.message));
  } finally {
    yield put(fetchScorecardsOptionsLoading(false));
  }
}

export function* updateInterviewScoreWorker({
  payload: { userId, isDraft, scorecard, draftKey, jobId, ...data }
}) {
  try {
    yield put(setIsUpdatingScoreLoading(true));
    const token = yield select(getToken);

    if (draftKey) {
      yield call(patchRequestWithToken, {
        url: `/api/scorecards/score/${draftKey}/`,
        token,
        payload: {
          ...data,
          is_draft: isDraft
        }
      });
    } else {
      const res = yield call(postRequestWithToken, {
        url: `/api/scorecards/candidate/${userId}/scores/`,
        token,
        payload: {
          candidate_key: userId,
          scorecard,
          ...data,
          is_draft: isDraft
        }
      });

      yield put(fetchCandidateScoreByCardIdResponse(res));
      yield put(updateCandidateScoreCardResponse(res));
    }

    if (!isDraft && jobId) {
      yield call(fetchUserCurrentJobWorker, {
        payload: { userId, jobId, backPath: "" }
      });
      yield call(fetchCandidateHistoryWorker, { payload: { userId } });
    }
  } catch (error) {
    //
  } finally {
    yield put(setIsUpdatingScoreLoading(false));
  }
}
export function* scorecardSaga() {
  yield takeEvery(fetchScorecards, fetchScorecardLibraryWatcher);
  yield takeEvery(fetchLatestScorecards, fetchLatestScorecardsLibrariesWorker);
  yield takeEvery(deleteScorecard, deleteScorecardWorker);
  yield takeEvery(duplicateScorecard, duplicateScorecardWorker);
  yield takeEvery(fetchCriteriaTitleSuggestions, fetchCriteriaTitleSuggestionWorker);
  yield takeEvery(fetchCriteriaDescriptionSuggestions, fetchCriteriaDescriptionSuggestionWorker);
  yield takeEvery(saveScoreCard, saveScorecardWorker);
  yield takeEvery(fetchScoreCardDetails, fetchScorecardDetailsWorker);
  yield takeEvery(fetchScorecardsOptions, fetchScorecardsOptionsWorker);
  yield takeEvery(fetchCandidateScores, fetchCandidateScoresWorker);
  yield takeEvery(
    fetchInterviewCardScorecard,
    fetchInterviewCardScorecardWorker
  );
  yield takeEvery(updateInterviewScore, updateInterviewScoreWorker);
  yield takeEvery(
    fetchCandidateScoreByCardId,
    fetchCandidateScoreByCardIdWorker
  );
}

export default scorecardSaga;
