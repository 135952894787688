/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import useWindowResize from "hooks/useWindowResize.tsx";
import { history } from "store";
import { ArrowDown, ArrowUp } from "mixins/svgIcons";
import BaseCard from "../Card/Base/BaseCard.tsx";
import { CardProps } from "../Card/Base/types.ts";

const NavigationButton: React.FC<{
  direction: "prev" | "next";
  onClick: () => void;
  disabled?: boolean;
}> = ({ direction, onClick, disabled }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    type="button"
    className={`
      text-gray-600 hover:text-gray-900 transition-colors
      ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
      outline-none focus:outline-none
    `}
  >
    {direction === "prev" ? (
      <FaArrowLeft size={20} />
    ) : (
      <FaArrowRight size={20} />
    )}
  </button>
);

export interface CarouselItem extends CardProps {
  id: string;
}

const Carousel = ({
  title,
  items,
  withProgress,
  showArrows,
  displayAllInMobile,
  showToggle
}: {
  title?: string;
  items: CarouselItem[];
  withProgress?: boolean;
  showArrows?:boolean
  displayAllInMobile?:boolean
  showToggle?: boolean;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isFirstVisible, setIsFirstVisible] = useState(true);
  const [isLastVisible, setIsLastVisible] = useState(false);
  const [isShown, setIsShown] = useState(true);
  const { isMobileScreen } = useWindowResize();

  const cardWidth = isMobileScreen ? window.innerWidth - 32 : 320;
  const gap = isMobileScreen ? 16 : 48;

  const checkLastCardVisibility = useCallback(() => {
    if (!containerRef.current) return false;

    const container = containerRef.current;
    const lastChild = container.lastElementChild?.lastElementChild;
    if (!lastChild) return false;

    const containerRight = container.getBoundingClientRect().right;
    const lastCardRight = lastChild.getBoundingClientRect().right;

    // Add a small buffer (1px) to account for potential floating point issues
    const isFullyVisible = lastCardRight <= containerRight + 1;
    return isFullyVisible;
  }, []);

  const updateScrollVisibility = useCallback(() => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const maxScroll = container.scrollWidth - container.clientWidth;
    const currentScroll = container.scrollLeft;

    // Update scroll progress
    setScrollProgress((currentScroll / maxScroll) * 100);

    // Check if at start
    setIsFirstVisible(currentScroll <= 1);

    // Check if last card is fully visible
    const lastCardVisible = checkLastCardVisibility();
    setIsLastVisible(lastCardVisible);
  }, [checkLastCardVisibility]);

  const smoothScrollFallback = useCallback((
    container: HTMLElement,
    targetScroll: number,
    duration: number = 500
  ) => {
    const startPosition = container.scrollLeft;
    const distance = targetScroll - startPosition;
    const startTime = performance.now();

    const easeInOutQuad = (t: number) =>
      (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

    const step = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);

      container.scrollLeft = startPosition + distance * easeInOutQuad(progress);

      if (progress < 1) {
        requestAnimationFrame(step);
      } else {
        // Update visibility after animation completes
        updateScrollVisibility();
      }
    };

    requestAnimationFrame(step);
  }, [updateScrollVisibility]);

  const handleScroll = useCallback((direction: "prev" | "next") => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const scrollOffset = isMobileScreen ? container.clientWidth + gap : cardWidth + gap;

    const scrollAmount = direction === "next"
      ? container.scrollLeft + scrollOffset
      : container.scrollLeft - scrollOffset;

    try {
      container.scrollTo({
        left: scrollAmount,
        behavior: "smooth"
      });
      // For browsers that support smooth scrolling, update after animation
      setTimeout(updateScrollVisibility, 500);
    } catch (error) {
      smoothScrollFallback(container, scrollAmount);
    }
  }, [isMobileScreen, gap, cardWidth, updateScrollVisibility, smoothScrollFallback]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScrollEvent = () => {
      requestAnimationFrame(updateScrollVisibility);
    };

    container.addEventListener("scroll", handleScrollEvent, { passive: true });

    // Initial check
    updateScrollVisibility();

    return () => {
      container.removeEventListener("scroll", handleScrollEvent);
    };
  }, [updateScrollVisibility]);

  // Update visibility on mount, resize, and items change
  useEffect(() => {
    updateScrollVisibility();
  }, [updateScrollVisibility, isMobileScreen, items]);

  return (
    <div className="relative">
      <div
        className="w-full flex flex-row justify-between py-10"

      >
        <h3 className={`font-poppins text-lg font-medium text-main-link ${title ? "visible" : "invisible"}`}>
          {title}
        </h3>

        <div className={`relative w-40 h-1 my-auto bg-gray-200 rounded-full overflow-hidden ${isMobileScreen ? "hidden" : ""} ${withProgress ? "visible" : "invisible"}`}>
          <div
            className="absolute top-0 left-0 h-full bg-blue-600 transition-transform duration-150"
            style={{ width: `${scrollProgress}%` }}
          />
        </div>

        {showArrows && (
          <div className="flex flex-row gap-6">
            {showToggle && (
              <button
                type="button"
                className="flex flex-row justify-end items-center font-poppins text-sm font-normal text-main-link gap-2 button__without-styles border-b-1 border-b-main-purple"
                onClick={() => setIsShown(prev => !prev)}
              >
                {isShown ? (
                  <>
                    Hide
                    <ArrowUp svgIconClass="" />
                  </>
                ) : (
                  <>
                    Show
                    <ArrowDown svgIconClass="" />
                  </>
                )}
              </button>
            )}
            <NavigationButton
              direction="prev"
              onClick={() => handleScroll("prev")}
              disabled={isFirstVisible}
            />
            <NavigationButton
              direction="next"
              onClick={() => handleScroll("next")}
              disabled={isLastVisible}
            />
          </div>
        )}

      </div>

      <div
        ref={containerRef}
        className="overflow-x-auto hide-scrollbar"
        style={{
          WebkitOverflowScrolling: "touch",
          scrollbarWidth: "none",
          msOverflowStyle: "none"
        }}
      >
        <div
          className={`flex ${isMobileScreen ? `gap-4 ${displayAllInMobile ? "flex-col" : "flex"}` : "gap-12"} pb-4`}
        >
          {items.map(item => (
            <div
              key={item.id}
              className={`flex-none w-full md:w-80 ${["new_tab", "self"].includes(item?.openIn || "") ? "cursor-pointer" : ""}`}
              onClick={() => {
                // if media, then the base card component should handle it
                if (item.openIn === "new_tab") {
                  window.open(item.url || "", "_blank");
                }

                if (item.openIn === "self") {
                  history.push(item.url || "");
                }
              }}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              <BaseCard
                {...item}
                title={{
                  className: "font-normal text-base font-poppins text-primary-black",
                  ...item.title
                }}
                description={{
                  className: "font-poppins text-sm font-normal text-secondary-grey text-wrap line-clamp-2",
                  ...item.description
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
