import { toUpper } from "lodash";

/* eslint-disable import/prefer-default-export */
export const capitalizeFirstLetter = string => toUpper(string.charAt(0)) + string.slice(1);

export function replaceAll(str, find, replace) {
  if (!str) return "";
  return str?.replace(new RegExp(find, "g"), replace);
}

const IRREGULAR_NOUNS = {
  child: "children",
  person: "people",
  tooth: "teeth"
  // Add more as needed
};

export const pluralize = (word, count, pluralForm = null) => {
  if (count === 1) return word;
  if (pluralForm) return pluralForm;
  if (IRREGULAR_NOUNS[word]) return IRREGULAR_NOUNS[word];

  return new Intl.PluralRules("en-US").select(count) === "one" ? word : `${word}s`;
};
