import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Head = () => {
  const { t } = useTranslation();
  const { pathname } = window.location;

  const indexablePaths = [
    "/sign-in",
    "/sign-up"
  ];

  const metaTags = useMemo(() => {
    const shouldIndex = indexablePaths.some(path =>
      pathname.startsWith(path));

    if (!shouldIndex) {
      return [
        { name: "robots", content: "noindex, nofollow" },
        { name: "googlebot", content: "noindex, nofollow" }
      ];
    }
    return [];
  }, [indexablePaths, pathname]);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="author" content={t("author")} />
      <meta
        name="description"
        property="og:description"
        content={t("description")}
      />
      <title>{t("title")}</title>
      {metaTags.map(tag => (
        <meta key={tag.name} name={tag.name} content={tag.content} />
      ))}
    </Helmet>
  );
};

export default Head;
