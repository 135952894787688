import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";

import { Circle, CircleWithCheck } from "mixins/svgIcons";
import animationData from "assets/images/components/onboarding/right-image.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Onboarding = ({
  t,
  jobsFirstId,
  agencyJobsAmount,
  companiesAmount,
  invitedAmount,
  animation
}) => (
  <div className="onboarding 1" style={{ animation }}>
    <div className="onboarding__left">
      <h2 className="onboarding__title n-font-huge n-grey-100">
        {t("widgets.onboarding.title")}
      </h2>
      <p className="onboarding__subtitle n-font-medium n-grey-100">
        <span>{t("widgets.onboarding.follow")}</span>
        &nbsp;
        {/* eslint-disable-next-line */}
        <a
          className="onboarding__link n-font-medium n-purple-100"
          rel="noopener noreferrer"
          href="#"
          data-beacon-article-modal="5ff1d71a40f24b1b2aaf5eb1"
          target="_blank"
        >
          {t("widgets.onboarding.watch")}
        </a>
      </p>

      <ul className="onboarding__list">
        <li className="onboarding__list-item">
          <Link className="onboarding__link" to="/companies/create/">
            {companiesAmount > 0 ? (
              <CircleWithCheck svgIconClass="onboarding__list-image" />
            ) : (
              <Circle svgIconClass="onboarding__list-image" />
            )}
          </Link>

          <span className="n-font-small n-black-100">
            {t("widgets.onboarding.setup")}
            <Link
              className="onboarding__link n-font-small n-purple-100"
              to="/companies/create"
            >
              {t("link.letsGo")}
            </Link>
          </span>
        </li>

        <li className="onboarding__list-item">
          <Link className="onboarding__link" to="/jobs/create">
            {agencyJobsAmount > 0 ? (
              <CircleWithCheck svgIconClass="onboarding__list-image" />
            ) : (
              <Circle svgIconClass="onboarding__list-image" />
            )}
          </Link>

          <span className="n-font-small n-black-100">
            {t("widgets.onboarding.create")}
            <Link
              className="onboarding__link n-font-small n-purple-100"
              to="/jobs/create"
            >
              {t("link.letsGo")}
            </Link>
          </span>
        </li>

        <li className="onboarding__list-item">
          <Link
            className="onboarding__link"
            to={jobsFirstId ? `/jobs/invite/${jobsFirstId}` : "/jobs"}
          >
            {invitedAmount >= 1 ? (
              <CircleWithCheck svgIconClass="onboarding__list-image" />
            ) : (
              <Circle svgIconClass="onboarding__list-image" />
            )}
          </Link>

          <span className=" n-font-small n-black-100">
            {t("widgets.onboarding.invite")}
            <Link
              className="onboarding__link n-font-small n-purple-100"
              to={jobsFirstId ? `/jobs/invite/${jobsFirstId}` : "/jobs"}
            >
              {t("link.letsGo")}
            </Link>
          </span>
        </li>
      </ul>
    </div>

    <div className="onboarding__right">
      <Lottie options={defaultOptions} height={150} width={200} />
    </div>
  </div>
);

Onboarding.defaultProps = {
  jobsFirstId: undefined,
  agencyJobsAmount: undefined,
  companiesAmount: undefined,
  invitedAmount: undefined,
  profileInfo: undefined,
  animation: undefined
};

Onboarding.propTypes = {
  t: PropTypes.func.isRequired,
  jobsFirstId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  agencyJobsAmount: PropTypes.number,
  companiesAmount: PropTypes.number,
  invitedAmount: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  profileInfo: PropTypes.shape({
    agency: PropTypes.string
  }),
  animation: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
};

export default withTranslation()(Onboarding);
