import React, { useCallback, useRef } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Header from "containers/Common/HeaderContainer";
import CardCompany from "components/Company/Card";
import Spinner from "components/Common/Spinner";
import PageWrapper from "hoc/PageWrapper";

const Companies = ({
  t,
  isLoading,
  history,
  currentUser,
  dataCompanies,
  fetchCompaniesForTable,
  setModal
}) => {
  const handleDelete = ({ isDefault, comapnyId }) => {
    setModal({
      type: isDefault ? "deleteDefaultCompany" : "deleteCompanyConfirmation",
      companyConfirmation: { value: comapnyId }
    });
  };

  const handleEdit = id => () => {
    history.push(`/companies/edit/${id}`);
  };

  const observer = useRef();

  const lastCompanyElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && dataCompanies?.next?.length) {
        fetchCompaniesForTable({ key: currentUser.key, next: dataCompanies?.next });
      }
    });
    if (node) observer.current.observe(node);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCompanies?.next]);

  return (
    <PageWrapper header={<Header />}>
      <Container className="companies">
        <Row justify="center">
          <>
            <Col md={4}>
              <Link
                className="link__create-company n-bg-white"
                to="companies/create"
                id="create-company-button"
              >
                <p>
                  <span className="link__create-company-icon">+</span>
                  <span className="link__create-company-text">
                    {t("button.createCompany")}
                  </span>
                </p>
              </Link>
            </Col>
            {dataCompanies &&
            dataCompanies?.results?.map((company, index) => (
              <Col md={4} key={`comapny-${company.key}`} ref={index === dataCompanies?.results?.length - 1 ? lastCompanyElementRef : null}>
                <CardCompany>
                  <CardCompany.Header isDefault={company.is_default}>
                    <Link
                      to={{
                        pathname: `/companies/overview/${company.key}`,
                        state: {
                          title: company.name
                        }
                      }}
                    >
                      {company.name}
                    </Link>
                  </CardCompany.Header>

                  <CardCompany.Footer
                    comapnyId={company.key}
                    jobsNumber={company.jobs_cnt}
                    isDefault={company.is_default}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit(company.key)}
                  />
                </CardCompany>
              </Col>
            ))}
          </>
        </Row>
        {
          isLoading && (
            <Row justify="center">
              <Spinner />
            </Row>
          )
        }
      </Container>
    </PageWrapper>
  );
};

Companies.defaultProps = {
  isLoading: false,
  history: undefined,
  currentUser: undefined,
  dataCompanies: undefined,
  fetchCompaniesForTable: undefined,
  setModal: undefined
};

Companies.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
    length: PropTypes.number
  }),
  currentUser: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string
  }),
  dataCompanies: PropTypes.shape({
    count: PropTypes.number,
    next: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.object)
  }),
  fetchCompaniesForTable: PropTypes.func,
  setModal: PropTypes.func
};

export default withTranslation()(Companies);
