/* eslint-disable camelcase */
import {
  delay,
  takeLatest,
  takeEvery,
  select,
  call,
  put
} from "redux-saga/effects";

// eslint-disable-next-line import/no-cycle
import { history } from "store";
import {
  isLibrariesLoading,
  isCurrentLibraryLoading,
  fetchLibraries,
  fetchLibrariesResponse,
  fetchLibrariesError,
  fetchLibrariesDivided,
  fetchLibrariesDividedResponse,
  fetchLibrariesDividedError,
  isLatestLibrariesLoading,
  fetchLatestLibraries,
  fetchLatestLibrariesResponse,
  duplicateLibrary,
  duplicateLibraryResponse,
  duplicateLibraryError,
  deleteLibrary,
  deleteLibraryResponse,
  deleteLibraryError,
  fetchCurrentLibrary,
  fetchCurrentLibraryResponse,
  fetchCurrentLibraryError,
  createLibrary,
  createLibraryError,
  updateLibrary,
  updateLibraryError
} from "store/modules/libraries/actions";
import { setLoadingScreen } from "store/modules/widgets/actions";
import { getToken } from "store/modules/auth/selectors";
import { getCurrentUserId } from "store/modules/users/selectors";

// eslint-disable-next-line import/no-cycle
import {
  getRequestWithToken,
  postRequestWithToken,
  patchRequestWithToken,
  deleteRequestWithToken
} from "./api";

export function* fetchLibrariesWorker({
  payload: { filter = {}, language } = {}
}) {
  try {
    yield put(isLibrariesLoading(true));

    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);
    const query = "&page_size=30";
    const filterQuery = `&title=${filter.title || ""}&type=${filter.type?.value || ""}`;

    const response = yield getRequestWithToken({
      url: `/api/templates/agency/${agencyKey}/list/?${query}${filterQuery}&language=${language ?? "en"}`,
      token
    });

    yield put(fetchLibrariesResponse(response.data));
  } catch (error) {
    console.log("[fetchLibrariesRequestError]", error);
    yield put(fetchLibrariesError("There is a problem with loading templates."));
  } finally {
    yield put(isLibrariesLoading(false));
  }
}

export function* fetchLibrariesDividedWorker({ payload: {
  language
} }) {
  try {
    yield put(isLibrariesLoading(true));

    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    const response = yield getRequestWithToken({
      url: `/api/templates/agency/${agencyKey}/list-divided/?language=${language}`,
      token
    });

    yield put(fetchLibrariesDividedResponse(response.data));
  } catch (error) {
    console.log("[fetchLibrariesDividedRequestError]", error);
    yield put(fetchLibrariesDividedError("There is a problem with loading templates."));
  } finally {
    yield put(isLibrariesLoading(false));
  }
}

export function* fetchLatestLibrariesWorker({ payload: { next } = {} }) {
  try {
    yield put(isLatestLibrariesLoading(true));
    const token = yield select(getToken);

    if (next) {
      // eslint-disable-next-line no-useless-escape
      const url = `/${next.match(/\/\/[^\/]+\/([^\.]+)/)[1]}`;
      const response = yield getRequestWithToken({
        url,
        token
      });
      yield put(fetchLatestLibrariesResponse(response.data));
    }
  } catch (error) {
    console.log("[fetchLatestLibrariesRequestError]", error);
    yield put(fetchLibrariesError("There is a problem with loading templates."));
  } finally {
    yield put(isLatestLibrariesLoading(false));
  }
}

export function* duplicateLibraryWorker({ payload: {
  key,
  filter,
  language
} }) {
  try {
    yield put(isLibrariesLoading(true));

    const token = yield select(getToken);
    const agency = yield select(getCurrentUserId);
    const response = yield call(postRequestWithToken, {
      url: `/api/templates/${key}/duplicate/`,
      token,
      payload: {
        agency
      }
    });

    if (response.status === 201) {
      yield put(fetchLibraries({ filter, language }));

      yield put(duplicateLibraryResponse(`Template ${response.data.title} was successfully added.`));
      yield delay(3000);
      yield put(duplicateLibraryResponse(null));
    }
  } catch (error) {
    yield put(duplicateLibraryError(error.response?.data?.detail || "There is a problem with duplicate template."));
  } finally {
    yield put(isLibrariesLoading(false));
  }
}

export function* deleteLibraryWorker({ payload }) {
  try {
    const token = yield select(getToken);
    const response = yield deleteRequestWithToken({
      url: `/api/templates/${payload}/delete/`,
      token
    });

    if (response.status === 204) {
      yield put(deleteLibraryResponse(payload));
    }
  } catch (error) {
    yield put(deleteLibraryError("There is a problem with delete template."));
  }
}

export function* fetchCurrentLibraryWorker({ payload = "" }) {
  try {
    yield put(isCurrentLibraryLoading(true));
    if (!payload) return;

    const token = yield select(getToken);

    const response = yield getRequestWithToken({
      url: `/api/templates/${payload}/`,
      token
    });
    yield put(fetchCurrentLibraryResponse(response.data));
  } catch (error) {
    yield put(fetchCurrentLibraryError("Can't fetch current template"));

    if (error.response?.status === 404) {
      yield call(history.push, "/page-not-found");
    }
  } finally {
    yield put(isCurrentLibraryLoading(false));
  }
}

export function* createLibraryWorker({ payload = {} }) {
  try {
    yield put(setLoadingScreen(true));

    const token = yield select(getToken);
    const agency = yield select(getCurrentUserId);
    const response = yield call(postRequestWithToken, {
      url: "/api/templates/create/",
      token,
      payload: {
        agency,
        ...payload
      }
    });

    if (response?.status === 201) yield call(history.push, "/libraries");
  } catch (error) {
    const contentError = error?.response?.data?.content_error;
    yield put(createLibraryError(contentError || {
      message: "There is a problem with creating template. Recheck all info and push save button."
    }));
    if (contentError?.red?.length === 0 && contentError?.amber?.length && typeof payload.callback === "function") {
      payload.callback();
    }
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* updateLibraryWorker({ payload = {} }) {
  try {
    yield put(setLoadingScreen(true));

    const token = yield select(getToken);
    const response = yield call(patchRequestWithToken, {
      url: `/api/templates/${payload.key}/update/`,
      token,
      payload
    });

    if (response?.status === 200) yield call(history.push, "/libraries");
  } catch (error) {
    const contentError = error?.response?.data?.content_error;
    yield put(updateLibraryError(contentError || {
      message: "There is a problem with updating template. Recheck all info and push save button."
    }));
    if (contentError?.red?.length === 0 && contentError?.amber?.length && typeof payload.callback === "function") {
      payload.callback();
    }
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* createLibrariesWatcher() {
  yield takeEvery(fetchLibraries, fetchLibrariesWorker);
  yield takeEvery(fetchLibrariesDivided, fetchLibrariesDividedWorker);
  yield takeLatest(fetchLatestLibraries, fetchLatestLibrariesWorker);
  yield takeEvery(duplicateLibrary, duplicateLibraryWorker);
  yield takeEvery(deleteLibrary, deleteLibraryWorker);
  yield takeEvery(fetchCurrentLibrary, fetchCurrentLibraryWorker);
  yield takeEvery(createLibrary, createLibraryWorker);
  yield takeEvery(updateLibrary, updateLibraryWorker);
}

export default createLibrariesWatcher;
