/* eslint-disable import/no-cycle */
import {
  takeLatest,
  takeEvery,
  select,
  put,
  all
} from "redux-saga/effects";

import {
  fetchPeople,
  fetchPeopleResponse,
  fetchPeopleError,
  setPeopleLoading,
  fetchPeopleFilters,
  fetchPeopleFiltersResponse,
  fetchPeopleFiltersError,
  setPeopleFiltersLoading,
  fetchLatestPeople,
  fetchLatestPeopleResponse,
  fetchLatestPeopleError,
  setLatestPeopleLoading,
  fetchLatestPeopleFilterCompanies,
  fetchLatestPeopleFilterCompaniesResponse,
  fetchLatestPeopleFilterCompaniesError,
  setLatestPeopleFilterCompaniesLoading,
  fetchLatestPeopleFilterJobs,
  fetchLatestPeopleFilterJobsResponse,
  fetchLatestPeopleFilterJobsError,
  setLatestPeopleFilterJobsLoading,
  fetchLatestPeopleFilterOwners,
  fetchLatestPeopleFilterOwnersResponse,
  fetchLatestPeopleFilterOwnersError,
  setLatestPeopleFilterOwnersLoading,
  fetchSearchPeopleFilter,
  fetchSearchPeopleFilterResponse,
  fetchSearchPeopleFilterError,
  setSearchPeopleFilterLoading,
  setLatestPeopleFilterRatingsLoading,
  setLatestPeopleFilterStatusesLoading
} from "store/modules/people/actions";
import { getToken } from "store/modules/auth/selectors";
import { getCurrentUserId } from "store/modules/users/selectors";


// eslint-disable-next-line import/no-cycle
import { getPeoplePageSelectedFilter } from "store/modules/people/selectors";
import {
  getRequestWithToken
} from "./api";

const getFilterQuery = ({
  filter, currFilter = "", ordering = "-submitted_at", search = ""
}) => (
  `search=${filter.title || ""}&ordering=${ordering}&filter_search=${search}&${
    [
      { name: "companies", query: "company" },
      { name: "jobs", query: "job" },
      { name: "owners", query: "owner" },
      { name: "ratings", query: "rank" },
      { name: "statuses", query: "status" }
    ].map(({ name, query }) => (
      currFilter === name ? (filter[name] || []).map(({ value }) => `selected=${encodeURIComponent(value)}`).join("&") : (filter[name] || []).map(({ value }) => `${query}=${encodeURIComponent(value)}`).join("&")
    )).join("&")
  }`
);

export function* fetchPeopleFiltersWorker({ payload: { key, filter } = {} }) {
  try {
    yield put(setPeopleFiltersLoading(true));

    const token = yield select(getToken);

    const [
      responseCompanies,
      responseJobs,
      responseOwners,
      responseRatings,
      responseStatuses
    ] = yield all(["companies", "jobs", "owners", "ratings", "statuses"].map(field => (
      getRequestWithToken({
        url: `/api/agency/${key}/people/${field}/?page_size=30&${
          getFilterQuery({ filter, currFilter: field })
        }`,
        token
      })
    )));

    yield put(fetchPeopleFiltersResponse({
      filters: {
        companiesSelected: responseCompanies.data?.selected,
        jobsSelected: responseJobs.data?.selected,
        ownersSelected: responseOwners.data?.selected,
        companies: responseCompanies.data?.results,
        jobs: responseJobs.data?.results,
        owners: responseOwners.data?.results,
        ratings: responseRatings.data,
        statuses: responseStatuses.data
      },
      filtersPaginationData: {
        companiesNext: responseCompanies.data?.next,
        jobsNext: responseJobs.data?.next,
        ownersNext: responseOwners.data?.next
      }
    }));
  } catch (error) {
    console.error("[fetchPeopleError]", error);
    yield put(fetchPeopleFiltersError(error));
  } finally {
    yield put(setPeopleFiltersLoading(false));
  }
}

export function* fetchPeopleWorker({ payload = {} }) {
  try {
    const { key, filter, ordering, withFilterUpdate } = payload;
    if (withFilterUpdate) yield put(fetchPeopleFilters(payload));

    yield put(setPeopleLoading(true));

    const token = yield select(getToken);
    const response = yield getRequestWithToken({
      url: `/api/agency/${key}/people/?page_size=20&${getFilterQuery({ filter, ordering })}`,
      token
    });

    yield put(fetchPeopleResponse(response.data));
  } catch (error) {
    console.error("[fetchPeopleError]", error);
    yield put(fetchPeopleError(error));
  } finally {
    yield put(setPeopleLoading(false));
  }
}

export function* fetchLatestPeopleWorker({ payload: { next } = {} }) {
  try {
    yield put(setLatestPeopleLoading(true));
    const token = yield select(getToken);

    if (next && token) {
      // eslint-disable-next-line consistent-return
      // eslint-disable-next-line no-useless-escape
      const url = `/${next.match(/\/\/[^\/]+\/([^\.]+)/)[1]}`;
      const response = yield getRequestWithToken({
        url,
        token
      });
      yield put(fetchLatestPeopleResponse(response.data));
    }
  } catch (error) {
    console.error("[fetchLatestPeopleError]", error);
    yield put(fetchLatestPeopleError(error));
  } finally {
    yield put(setLatestPeopleLoading(false));
  }
}

export function* fetchLatestPeopleFilterCompaniesWorker({ payload }) {
  try {
    yield put(setLatestPeopleFilterCompaniesLoading(true));
    const token = yield select(getToken);

    if (payload && token) {
      // eslint-disable-next-line consistent-return
      // eslint-disable-next-line no-useless-escape
      const url = `/${payload.match(/\/\/[^\/]+\/([^\.]+)/)[1]}`;
      const response = yield getRequestWithToken({
        url,
        token
      });
      yield put(fetchLatestPeopleFilterCompaniesResponse(response.data));
    }
  } catch (error) {
    console.error("[fetchLatestPeopleFilterCompaniesError]", error);
    yield put(fetchLatestPeopleFilterCompaniesError(error));
  } finally {
    yield put(setLatestPeopleFilterCompaniesLoading(false));
  }
}

export function* fetchLatestPeopleFilterJobsWorker({ payload }) {
  try {
    yield put(setLatestPeopleFilterJobsLoading(true));
    const token = yield select(getToken);

    if (payload && token) {
      // eslint-disable-next-line consistent-return
      // eslint-disable-next-line no-useless-escape
      const url = `/${payload.match(/\/\/[^\/]+\/([^\.]+)/)[1]}`;
      const response = yield getRequestWithToken({
        url,
        token
      });
      yield put(fetchLatestPeopleFilterJobsResponse(response.data));
    }
  } catch (error) {
    console.error("[fetchLatestPeopleFilterJobsError]", error);
    yield put(fetchLatestPeopleFilterJobsError(error));
  } finally {
    yield put(setLatestPeopleFilterJobsLoading(false));
  }
}

export function* fetchLatestPeopleFilterOwnersWorker({ payload }) {
  try {
    yield put(setLatestPeopleFilterOwnersLoading(true));
    const token = yield select(getToken);

    if (payload && token) {
      // eslint-disable-next-line consistent-return
      // eslint-disable-next-line no-useless-escape
      const url = `/${payload.match(/\/\/[^\/]+\/([^\.]+)/)[1]}`;
      const response = yield getRequestWithToken({
        url,
        token
      });
      yield put(fetchLatestPeopleFilterOwnersResponse(response.data));
    }
  } catch (error) {
    console.error("[fetchLatestPeopleFilterOwnersError]", error);
    yield put(fetchLatestPeopleFilterOwnersError(error));
  } finally {
    yield put(setLatestPeopleFilterOwnersLoading(false));
  }
}

export function* fetchSearchPeopleFilterWorker({ payload: { field, search } = {} }) {
  try {
    yield put(setSearchPeopleFilterLoading(true));
    const filter = yield select(getPeoplePageSelectedFilter);
    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    if (field === "companies") {
      yield put(setLatestPeopleFilterCompaniesLoading(true));
    }
    if (field === "jobs") {
      yield put(setLatestPeopleFilterJobsLoading(true));
    }
    if (field === "owners") {
      yield put(setLatestPeopleFilterOwnersLoading(true));
    }
    if (field === "ratings") {
      yield put(setLatestPeopleFilterRatingsLoading(true));
    }
    if (field === "statuses") {
      yield put(setLatestPeopleFilterStatusesLoading(true));
    }

    const response = yield getRequestWithToken({
      url: `/api/agency/${agencyKey}/people/${field}/?page_size=30&${
        getFilterQuery({ filter: { ...filter, title: search }, currFilter: field, search: search || "" })
      }`,
      token
    });


    if (["statuses", "ratings"].includes(field)) {
      yield put(fetchSearchPeopleFilterResponse({
        filters: {
          [field]: response.data
        }
      }));
    } else {
      yield put(fetchSearchPeopleFilterResponse({
        filters: {
          [field]: response.data?.results
        },
        filtersPaginationData: {
          [`${field}Next`]: response.data?.next
        }
      }));
    }
  } catch (error) {
    console.error("[fetchSearchPeopleFilterError]", error);
    yield put(fetchSearchPeopleFilterError(error));
  } finally {
    yield put(setSearchPeopleFilterLoading(false));
    if (field === "companies") {
      yield put(setLatestPeopleFilterCompaniesLoading(false));
    }
    if (field === "jobs") {
      yield put(setLatestPeopleFilterJobsLoading(false));
    }
    if (field === "owners") {
      yield put(setLatestPeopleFilterOwnersLoading(false));
    }
    if (field === "ratings") {
      yield put(setLatestPeopleFilterRatingsLoading(false));
    }
    if (field === "statuses") {
      yield put(setLatestPeopleFilterStatusesLoading(false));
    }
  }
}

export function* createPeopleWatcher() {
  yield takeLatest(fetchPeople, fetchPeopleWorker);
  yield takeLatest(fetchPeopleFilters, fetchPeopleFiltersWorker);
  yield takeEvery(fetchLatestPeople, fetchLatestPeopleWorker);
  yield takeEvery(fetchLatestPeopleFilterCompanies, fetchLatestPeopleFilterCompaniesWorker);
  yield takeEvery(fetchLatestPeopleFilterJobs, fetchLatestPeopleFilterJobsWorker);
  yield takeEvery(fetchLatestPeopleFilterOwners, fetchLatestPeopleFilterOwnersWorker);
  yield takeEvery(fetchSearchPeopleFilter, fetchSearchPeopleFilterWorker);
}

export default createPeopleWatcher;
