import React, { useMemo } from "react";
import { getButtonStyles } from "components/Common/Button/Button.tsx";
import { FaPlus } from "react-icons/fa6";
import fingerprintImg from "../../assets/images/landing/fingerprint.png";
import sunImg from "../../assets/images/landing/sun.png";

const EmptyStateHeader = ({
  handleCreateJob
}: {
  handleCreateJob: () => void;
}) => {
  const greeting = useMemo(() => {
    const currentTime = new Date().getHours();

    if (currentTime >= 5 && currentTime < 12) {
      return "Good Morning";
    }

    if (currentTime >= 12 && currentTime < 17) {
      return "Good Afternoon";
    }

    return "Good Evening";
  }, []);


  return (
    <div className="h-80 bg-yellow-200">
      <div className="container h-full flex justify-between py-8 relative overflow-hidden">
        <div
          className="absolute right-0 -translate-y-1/2 fingerprint-container"
        >
          <img
            src={fingerprintImg}
            alt="fingerprint background"
            className="w-full h-full object-cover"
          />
        </div>

        <div className="flex flex-col md:flex-row w-full relative">
          <div className="flex-1 md:self-end">
            <img
              src={sunImg}
              alt="fingerprint background"
              className="h-8"
            />

            <h1 className="font-poppins text-4xl font-semibold leading-12">{`${greeting}, User`}</h1>
            <span className="font-poppins text-xs font-normal text-primary-black">Last seen: Today</span>
          </div>
          <div className="md:self-end">
            <button
              type="button"
              className={getButtonStyles("primary")}
              onClick={handleCreateJob}
            >
              Create New Interview
              <FaPlus size="14" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyStateHeader;
