import { handleActions, combineActions } from "redux-actions";
import uuidv4 from "uuid/v4";

import { JOB_STATUSES } from "configs/jobs/constants";

import { getConnectedJobs, getTotalJobsAndStagesCount, getJobKeysFromJobsAndStages, removeDuplicates } from "mixins/helpers";
import { isJobsLoading,
  isLatestJobsLoading,
  isSendingUploading,
  cashCurrentJob,
  addJobInvite,
  updateJobInvite,
  deleteJobInvite,
  setJobInvites,
  clearJobInvites,
  fetchJobsResponse,
  fetchLatestJobsResponse,
  saveJobResponse,
  updateJobResponse,
  duplicateJobSuccess,
  fetchCurrentJobResponse,
  fetchCurrentJobError,
  sendSpreadsheetResponse,
  sendSpreadsheetError,
  sendInvitesResponse,
  sendInvitesError,
  duplicateJobError,
  clearSuccessMessage,
  clearErrors,
  clearCurrentJob, clearJobs,
  clearJobInvitesParseData,
  setVideoError,
  deleteJobResponse,
  restoreJobResponse,
  fetchJobsFiltersResponse,
  setJobsFiltersLoading,
  fetchLanguagesForQuestionResponse,
  updateJobsListAtsDetails,
  getJobAtsDetailsResponse,
  updateAvailableAtsJobs,
  isJobAtsLoading,
  getAvailableAtsJobStagesResponse,
  resetJobAtsDetails,
  getAvailableAtsJobStagesLoading,
  updateNextAvailableAtsJobs,
  subscribeToAtsJobLoading,
  fetchJobsCompanyFilterResponse,
  fetchJobsOwnersFilterResponse,
  fetchJobsStatusesFilterResponse,
  getNextAtsJobsLoading,
  filterAtsJobsResponse,
  resetFilterAtsJobs,
  filterAtsJobsLoading,
  subscribeToAtsJobResponse,
  saveCandidatesExportLink,
  updateCurrentJobQuestions,
  setSidebarOpen } from "./actions";
import { logOut } from "../auth/actions";


const defaultState = {
  jobAtsDetails: {
    isLoading: true,
    savedJobsAndStagesCount: 0,
    isJobsLoading: false,
    isFilterLoading: false
  },
  isLoading: false,
  isSendingUploading: false,
  isJobInvitesSend: false,
  tablesData: {
    results: []
  },
  jobInvitesData: [],
  jobInvitesParseData: null,
  success: null,
  error: null,
  videoError: null,
  tablesFilters: {},
  isFiltersLoading: false,
  candidatesExportLink: null,
  isSidebarOpen: "transcription"
};

export default handleActions(
  {
    [isJobsLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [setVideoError]: (state, { payload }) => ({
      ...state,
      videoError: payload
    }),
    [isLatestJobsLoading]: (state, { payload }) => ({
      ...state,
      isLatestJobsLoading: payload
    }),
    [isSendingUploading]: (state, { payload }) => ({
      ...state,
      isSendingUploading: payload
    }),
    [clearJobs]: state => ({
      ...state,
      tablesData: {
        results: []
      }
    }),
    [fetchJobsResponse]: (state, { payload }) => ({
      ...state,
      tablesData: payload
    }),
    [fetchLatestJobsResponse]: (state, { payload: { next, previous, count, results } }) => ({
      ...state,
      tablesData: { next, previous, count, results: [...state.tablesData.results, ...results] }
    }),
    [saveJobResponse]: (
      state,
      { payload }
    ) => ({
      ...state,
      tablesData: {
        ...state.tablesData,
        results: [...state.tablesData.results, payload]
      }
    }),
    [duplicateJobSuccess]: (state, { payload }) => ({
      ...state,
      success: payload
    }),
    [updateJobResponse]: (state, { payload }) => ({
      ...state,
      tablesData: {
        ...state.tablesData,
        results: state.tablesData.results.map(job => (job.key === payload.key ? payload : job))
      }
    }),
    [combineActions(fetchCurrentJobResponse, cashCurrentJob)]: (
      state,
      { payload }
    ) => ({
      ...state,
      jobCurrent: payload,
      tablesData: {
        ...state.tablesData,
        results: [...state.tablesData.results.map(item => (item.key === payload.key ? {
          ...item,
          status: payload.status,
          deadline: payload.deadline
        } : item))]
      }
    }),
    [restoreJobResponse]: (
      state,
      { payload }
    ) => ({
      ...state,
      jobCurrent: {
        ...state.jobCurrent,
        status: JOB_STATUSES.LIVE,
        deadline: null
      },
      tablesData: {
        ...state.tablesData,
        results: [...state.tablesData.results.map(item => (item.key === payload ? {
          ...item,
          status: JOB_STATUSES.LIVE,
          deadline: null
        } : item))]
      }
    }),
    [clearCurrentJob]: state => ({
      ...state,
      jobCurrent: {}
    }),
    [deleteJobResponse]: (state, { payload }) => ({
      ...state,
      tablesData: {
        ...state.tablesData,
        results: state.tablesData.results.filter(item => item.key !== payload),
        count: state.tablesData.count - 1
      }
    }),

    [fetchCurrentJobError]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [addJobInvite]: (state, { payload }) => ({
      ...state,
      jobInvitesData: [...state.jobInvitesData, payload]
    }),
    [sendSpreadsheetResponse]: (state, { payload: { data, parse } }) => {
      const interviewersWithKeys = data.map(item => {
        if (!item.key) {
          item.key = uuidv4();
        }

        return item;
      });

      return {
        ...state,
        jobInvitesData: [...state.jobInvitesData, ...interviewersWithKeys],
        jobInvitesParseData: parse
      };
    },
    [duplicateJobError]: (
      state,
      { payload }
    ) => ({
      ...state,
      error: payload
    }),
    [sendSpreadsheetError]: (
      state,
      { payload }
    ) => ({
      ...state,
      parseError: payload
    }),
    [sendInvitesResponse]: state => ({
      ...state,
      isJobInvitesSend: true,
      error: null
    }),
    [sendInvitesError]: (state, { payload }) => ({
      ...state,
      isJobInvitesSend: false,
      error: payload
    }),
    [updateJobInvite]: (state, { payload }) => ({
      ...state,
      jobInvitesData: state.jobInvitesData.map(item => {
        if (item.key === payload.key) {
          return {
            ...item,
            ...payload
          };
        }

        return item;
      })
    }),
    [deleteJobInvite]: (state, { payload }) => ({
      ...state,
      jobInvitesData: state.jobInvitesData.filter(item => item.key !== payload)
    }),
    [setJobInvites]: (state, { payload }) => ({
      ...state,
      jobInvitesData: payload
    }),
    [clearSuccessMessage]: state => ({
      ...state,
      success: null
    }),
    [clearJobInvites]: state => ({
      ...state,
      jobInvitesData: [],
      jobInvitesParseData: null,
      parseError: null
    }),
    [clearJobInvitesParseData]: state => ({
      ...state,
      jobInvitesParseData: null,
      parseError: null
    }),
    [clearErrors]: state => ({
      ...state,
      isJobInvitesSend: false,
      error: null,
      parseError: null
    }),
    [fetchJobsFiltersResponse]: (state, { payload }) => ({
      ...state,
      tablesFilters: payload
    }),
    [fetchJobsCompanyFilterResponse]: (state, { payload }) => ({
      ...state,
      tablesFilters: {
        ...state.tablesFilters,
        companies: payload
      }
    }),
    [fetchJobsOwnersFilterResponse]: (state, { payload }) => ({
      ...state,
      tablesFilters: {
        ...state.tablesFilters,
        owners: payload
      }
    }),
    [fetchJobsStatusesFilterResponse]: (state, { payload }) => ({
      ...state,
      tablesFilters: {
        ...state.tablesFilters,
        statuses: payload
      }
    }),
    [setJobsFiltersLoading]: (state, { payload }) => ({
      ...state,
      isFiltersLoading: payload
    }),
    [fetchLanguagesForQuestionResponse]: (state, { payload }) => ({
      ...state,
      languagesOptions: payload
    }),
    [isJobAtsLoading]: (state, { payload }) => {
      const { jobAtsDetails } = state;

      return {
        ...state,
        jobAtsDetails: {
          ...jobAtsDetails,
          isLoading: payload
        }
      };
    },
    [updateJobsListAtsDetails]: (state, { payload }) => {
      const { tablesData } = state;

      const updatedList = tablesData?.results
        .map(job => {
          if (job.key === payload.interviewKey) {
            return { ...job, ats: payload.response };
          }

          return job;
        });

      return {
        ...state,
        tablesData: {
          ...state.tablesData,
          results: updatedList
        }
      };
    },
    [getJobAtsDetailsResponse]: (state, { payload }) => {
      const { jobAtsDetails } = state;

      return {
        ...state,
        jobAtsDetails: {
          ...jobAtsDetails,
          atsConnected: payload,
          isFilterLoading: false
        }
      };
    },
    [updateAvailableAtsJobs]: (state, { payload }) => {
      const { jobAtsDetails } = state;

      const { availableJobs: currentAvailableJobs } = jobAtsDetails;

      let availableJobs = {
        atsKeys: [{
          key: payload?.atsKey,
          next: payload?.next
        }],
        results: [
          ...payload?.results?.map(item => ({
            ...item,
            atsKey: payload.atsKey
          }))
        ]
      };

      // to be used when scrolling through jobs
      if (currentAvailableJobs?.results) {
        availableJobs = {
          atsKeys: removeDuplicates([...currentAvailableJobs?.atsKeys, ...availableJobs.atsKeys], "key"),
          results: removeDuplicates([...currentAvailableJobs?.results, ...availableJobs.results], "remote_id")
            ?.sort((a, b) => b.is_connected - a.is_connected)
        };
      }

      // currently saved jobs
      const connectedJobs = getConnectedJobs(jobAtsDetails?.atsConnected);

      return {
        ...state,
        jobAtsDetails: {
          ...jobAtsDetails,
          availableJobs,
          savedJobsAndStagesCount: getTotalJobsAndStagesCount(connectedJobs),
          savedJobsAndStages: connectedJobs ?? [],
          isFilterLoading: false
        }
      };
    },
    [updateNextAvailableAtsJobs]: (state, { payload }) => {
      const { jobAtsDetails } = state;

      const { availableJobs: currentAvailableJobs } = jobAtsDetails;

      let { next } = payload;

      if (!payload.results?.length) next = null;

      let availableJobs = {
        atsKeys: [{
          key: payload?.atsKey,
          next
        }],
        results: [
          ...payload?.results?.map(item => ({
            ...item,
            atsKey: payload.atsKey
          }))
        ]
      };

      // // to be used when scrolling through jobs
      if (currentAvailableJobs?.results) {
        availableJobs = {
          atsKeys: removeDuplicates([...currentAvailableJobs?.atsKeys, ...availableJobs.atsKeys], "key"),
          results: removeDuplicates([...currentAvailableJobs?.results, ...availableJobs.results], "remote_id")
        };
      }

      return {
        ...state,
        jobAtsDetails: {
          ...jobAtsDetails,
          availableJobs,
          isFilterLoading: false
        }
      };
    },
    [filterAtsJobsResponse]: (state, { payload }) => {
      const { jobAtsDetails } = state;

      const { availableJobs: currentAvailableJobs } = jobAtsDetails;

      let { next } = payload;

      if (!payload.results?.length) next = null;

      let availableJobs = {
        atsKeys: [{
          key: payload?.atsKey,
          next
        }],
        results: [
          ...payload?.results?.map(item => ({
            ...item,
            atsKey: payload.atsKey
          }))
        ]
      };

      if (currentAvailableJobs?.results) {
        availableJobs = {
          ...availableJobs,
          atsKeys: removeDuplicates([...currentAvailableJobs?.atsKeys, ...availableJobs.atsKeys], "key")
        };
      }

      return {
        ...state,
        jobAtsDetails: {
          ...jobAtsDetails,
          availableJobs,
          isFilterLoading: false
        }
      };
    },
    [getAvailableAtsJobStagesLoading]: (state, { payload }) => {
      const { jobAtsDetails } = state;

      return {
        ...state,
        jobAtsDetails: {
          ...jobAtsDetails,
          isStagesLoading: payload,
          isFilterLoading: false
        }
      };
    },
    [getAvailableAtsJobStagesResponse]: (state, { payload }) => {
      const { jobAtsDetails } = state;
      return {
        ...state,
        jobAtsDetails: {
          ...jobAtsDetails,
          availableStages: payload,
          isFilterLoading: false
        }
      };
    },
    [subscribeToAtsJobLoading]: (state, { payload }) => {
      const { jobAtsDetails } = state;

      return {
        ...state,
        jobAtsDetails: {
          ...jobAtsDetails,
          isSubscribeToAtsJobLoading: payload,
          isFilterLoading: false
        }
      };
    },
    [subscribeToAtsJobResponse]: (state, { payload: { atsKey, callbacks } }) => {
      const { jobAtsDetails } = state;

      const updatedAtsConnectedJobs = jobAtsDetails?.atsConnected?.map(ats => {
        if (ats?.key === atsKey) ats.callbacks = callbacks;

        return ats;
      });

      const savedJobsAndStages = callbacks.map(callback => ({
        ...callback,
        atsKey
      }));

      const connectedJobKeys = getJobKeysFromJobsAndStages(savedJobsAndStages);

      const availableJobs = {
        ...jobAtsDetails.availableJobs,
        results: jobAtsDetails.availableJobs.results?.map(item => {
          item.is_connected = !!connectedJobKeys.includes(item.remote_id);

          return item;
        })?.sort((a, b) => b.is_connected - a.is_connected)
      };

      return {
        ...state,
        jobAtsDetails: {
          ...jobAtsDetails,
          atsConnected: updatedAtsConnectedJobs,
          savedJobsAndStagesCount: getTotalJobsAndStagesCount(callbacks),
          savedJobsAndStages,
          availableJobs
        }
      };
    },
    [resetJobAtsDetails]: state => ({
      ...state,
      jobAtsDetails: {
        ...defaultState.jobAtsDetails
      }
    }),
    [getNextAtsJobsLoading]: (state, { payload }) => {
      const { jobAtsDetails } = state;

      return {
        ...state,
        jobAtsDetails: {
          ...jobAtsDetails,
          isJobsLoading: payload,
          isFilterLoading: false
        }
      };
    },
    [resetFilterAtsJobs]: state => ({
      ...state,
      jobAtsDetails: {
        ...state.jobAtsDetails,
        availableJobs: {},
        isFilterLoading: true
      }
    }),
    [filterAtsJobsLoading]: (state, { payload }) => ({
      ...state,
      jobAtsDetails: {
        ...state.jobAtsDetails,
        isFilterLoading: payload
      }
    }),
    [saveCandidatesExportLink]: (state, { payload }) => ({
      ...state,
      candidatesExportLink: payload?.link
    }),
    [updateCurrentJobQuestions]: (state, { payload }) => ({
      ...state,
      jobCurrent: {
        ...state.jobCurrent,
        questions: payload
      }
    }),
    [setSidebarOpen]: (state, { payload }) => ({
      ...state,
      isSidebarOpen: payload
    }),
    [logOut]: state => ({
      ...state,
      ...defaultState,
      isLoading: true
    })
  },
  defaultState
);
