import React from "react";
import PageWrapper from "hoc/PageWrapper";
import Header from "containers/Common/HeaderContainer";
import Carousel from "components/Common/Carousel/Carousel.tsx";
import { FiPlus } from "react-icons/fi";
import { isMobile } from "react-device-detect";
import viewDemoImg from "../../assets/images/landing/view-demo.png";
import startInterviewImg from "../../assets/images/landing/start-interview.png";
import startTemplateImg from "../../assets/images/landing/start-template.png";
import EmptyStateCarousel from "./EmptyStateCarousel.tsx";
import EmptyStateHeader from "./EmptyStateHeader.tsx";

const EmptyStateLanding = ({
  handleCreateJob
}: {
  handleCreateJob: () => void;
}) => (
  <PageWrapper header={<Header />}>
    <EmptyStateHeader handleCreateJob={handleCreateJob} />
    <div
      className="container pb-16"
      id="empty-state-landing"
      style={{
        paddingLeft: isMobile ? undefined : 0,
        paddingRight: isMobile ? undefined : 0
      }}
    >
      <Carousel
        showArrows={false}
        displayAllInMobile
        title="Getting Started"
        items={[
          {
            id: "start-template",
            title: {
              text: "Start with a template"
            },
            description: {
              text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do."
            },
            headerMedia: {
              src: startTemplateImg,
              type: "image"
            },
            button: {
              text: "Library",
              color: "default",
              to: "/templates"
            }
          },
          {
            id: "create-interview",
            title: {
              text: "Create a New Interview"
            },
            description: {
              text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do."
            },
            headerMedia: {
              src: startInterviewImg,
              type: "image"
            },
            button: {
              text: "Create",
              iconRight: <FiPlus size={18} />,
              color: "default",
              onClick: handleCreateJob
            }
          },
          {
            id: "view-demo",
            title: {
              text: "View a Demo"
            },
            description: {
              text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do."
            },
            headerMedia: {
              src: viewDemoImg,
              type: "image"
            },
            button: {
              text: "Demo",
              color: "default",
              to: "/demo"
            }
          }
        ]}
      />
    </div>
    <EmptyStateCarousel />
  </PageWrapper>
);

export default EmptyStateLanding;
