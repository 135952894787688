/* eslint-disable import/prefer-default-export */
/* eslint-disable no-nested-ternary */

import * as Sentry from "@sentry/browser";

// Error categories for better grouping
const ERROR_CATEGORIES = {
  RECORDING: "recording_error",
  UPLOAD: "upload_error",
  DEVICE: "device_error",
  PERMISSION: "permission_error",
  NETWORK: "network_error",
  API: "api_error",
  AUTH: "auth_error",
  CHUNK: "chunk_error", // For chunk loading errors
  WORKER: "worker_error", // For web worker errors
  UNKNOWN: "unknown_error"
};

// Generic error patterns to group similar errors
const ERROR_PATTERNS = {
  CHUNK_LOAD: /loading.+chunk|chunk.+failed/i,
  WORKER_ERROR: /Worker|signInWorker|createError/i,
  NETWORK_GENERIC: /Network Error|network|CORS|timeout/i,
  UPLOAD_RELATED: /upload|blob|amazon|s3/i
};

/**
 * Detect error category based on error details
 */
const detectErrorCategory = error => {
  // Detect chunk loading errors
  if (ERROR_PATTERNS.CHUNK_LOAD.test(error?.message) ||
      error?.message?.includes("chunk")) {
    return ERROR_CATEGORIES.CHUNK;
  }

  // Detect worker errors
  if (ERROR_PATTERNS.WORKER_ERROR.test(error?.message)) {
    return ERROR_CATEGORIES.WORKER;
  }

  // Detect recording/media errors
  if (error?.name === "NotReadableError" ||
      error?.name === "NotAllowedError" ||
      error?.message?.includes("getUserMedia") ||
      error?.message?.includes("MediaRecorder")) {
    return ERROR_CATEGORIES.RECORDING;
  }

  // Detect upload errors
  if (error?.message?.includes("blob") ||
      error?.message?.includes("upload") ||
      error?.config?.url?.includes("upload-info") ||
      error?.config?.url?.includes("amazon")) {
    return ERROR_CATEGORIES.UPLOAD;
  }

  // Detect device errors
  if (error?.name === "DevicesNotFoundError" ||
      error?.message?.includes("device") ||
      error?.message?.includes("camera") ||
      error?.message?.includes("microphone")) {
    return ERROR_CATEGORIES.DEVICE;
  }

  // Detect permission errors
  if (error?.name === "NotAllowedError" ||
      error?.message?.includes("permission")) {
    return ERROR_CATEGORIES.PERMISSION;
  }

  // Detect network errors
  if (error?.message?.includes("network") ||
      error?.message?.includes("Network Error") ||
      error?.request) {
    return ERROR_CATEGORIES.NETWORK;
  }

  // Detect auth errors
  if (error?.response?.status === 401 ||
      error?.response?.status === 403) {
    return ERROR_CATEGORIES.AUTH;
  }

  // Default to API error if we have a response
  if (error?.response) {
    return ERROR_CATEGORIES.API;
  }

  return ERROR_CATEGORIES.UNKNOWN;
};

/**
 * Get standardized endpoint pattern
 */
/**
 * Get standardized endpoint pattern to group similar endpoints
 */
const getEndpointPattern = url => {
  if (!url) return "unknown";

  // Extract meaningful parts of the URL
  const parts = url.split("/").filter(Boolean);

  // Group by resource type rather than specific endpoints
  if (url.includes("upload-info") || url.includes("amazon")) {
    return "upload-api";
  }

  // Group summary endpoints
  if (url.includes("summary")) {
    return "summary-api";
  }

  // Group by core resource types
  const resourceTypes = {
    candidates: "candidate-api",
    questions: "question-api",
    jobs: "job-api",
    interviews: "interview-api",
    users: "user-api",
    answers: "answer-api",
    recordings: "recording-api"
  };

  // Find matching resource type using array methods
  const matchedResource = Object.entries(resourceTypes)
    .find(([type]) => parts.includes(type));

  if (matchedResource) {
    return matchedResource[1]; // Return the pattern
  }

  // For worker/chunk errors, group by error type
  if (url.includes("chunk") || url.includes("worker")) {
    return "worker-chunk-error";
  }

  // Default to a generic API pattern
  return "generic-api";
};

/**
 * Get a standardized error title for grouping
 */
const getErrorTitle = (error, type, category) => {
  const endpoint = getEndpointPattern(error?.config?.url);

  switch (category) {
  case ERROR_CATEGORIES.RECORDING:
    return `[${type}] Recording error: ${error?.name || "Unknown recording issue"}`;

  case ERROR_CATEGORIES.UPLOAD:
    return `[${type}] Upload error on ${endpoint}`;

  case ERROR_CATEGORIES.DEVICE:
    return `[${type}] Device error: ${error?.name || "Unknown device issue"}`;

  case ERROR_CATEGORIES.PERMISSION:
    return `[${type}] Permission error: ${error?.name || "Access denied"}`;

  case ERROR_CATEGORIES.NETWORK:
    return `[${type}] Network error on ${endpoint}`;

  case ERROR_CATEGORIES.AUTH:
    return `[${type}] Authentication error`;

  case ERROR_CATEGORIES.API:
    return `[${type}] ${error.response.status} error on ${endpoint}`;

  default:
    return `[${type}] Unknown error`;
  }
};

/**
 * Get error grouping fingerprint based on category
 */
const getErrorFingerprint = (error, type, category) => {
  const endpoint = getEndpointPattern(error?.config?.url);
  const baseFingerprint = [type, category];

  switch (category) {
  case ERROR_CATEGORIES.RECORDING:
    return [
      ...baseFingerprint,
        error?.name || "unknown_recording_error",
        error?.message?.includes("getUserMedia") ? "getUserMedia" : "recording"
    ];

  case ERROR_CATEGORIES.UPLOAD:
    return [
      ...baseFingerprint,
      endpoint,
        error?.response?.status ? String(error.response.status) : "unknown_status"
    ];

  case ERROR_CATEGORIES.DEVICE:
    return [
      ...baseFingerprint,
        error?.name || "unknown_device_error",
        error?.message?.includes("camera") ? "camera" :
        error?.message?.includes("microphone") ? "microphone" : "device"
    ];

  case ERROR_CATEGORIES.PERMISSION:
    return [
      ...baseFingerprint,
        error?.name || "unknown_permission_error"
    ];

  case ERROR_CATEGORIES.NETWORK:
    return [
      ...baseFingerprint,
      endpoint
    ];

  case ERROR_CATEGORIES.AUTH:
    return [
      ...baseFingerprint,
      String(error?.response?.status || "unknown")
    ];

  case ERROR_CATEGORIES.API:
    return [
      ...baseFingerprint,
      endpoint,
      String(error?.response?.status || "unknown"),
        error?.response?.data?.error || "unknown_error"
    ];

  default:
    return [
      ...baseFingerprint,
      "unknown_error"
    ];
  }
};

/**
 * Rate limiting for Sentry events to prevent 429s
 */
const RATE_LIMIT = {
  windowMs: 60000, // 1 minute
  maxEvents: 40, // max events per window
  events: new Map()
};

/**
 * Check if we should send event to Sentry based on rate limiting
 */
const shouldSendEvent = eventKey => {
  const now = Date.now();
  const eventTimes = RATE_LIMIT.events.get(eventKey) || [];

  // Clean up old events
  const recentEvents = eventTimes.filter(time => time > now - RATE_LIMIT.windowMs);

  if (recentEvents.length >= RATE_LIMIT.maxEvents) {
    return false;
  }

  // Update events
  RATE_LIMIT.events.set(eventKey, [...recentEvents, now]);
  return true;
};

/**
 * Generate event key for rate limiting
 */
const getEventKey = (error, type, category) => `${type}_${category}_${error?.response?.status || "unknown"}`;

/**
 * Enhanced error logging utility with rate limiting
 */
export const logErrors = ({ error, payload = null, context = {} }) => {
  const {
    userId = null,
    questionId = null,
    jobId = null,
    email = null,
    answerId = null,
    info = null,
    type = "candidate"
  } = context;

  // Detect error category
  const category = detectErrorCategory(error);

  // Generate event key for rate limiting
  const eventKey = getEventKey(error, type, category);

  // Check rate limiting
  if (!shouldSendEvent(eventKey)) {
    console.log(`Rate limited error event: ${eventKey}`);
    return;
  }

  // Clean and prepare context
  const extraContext = {
    timestamp: new Date().toISOString(),
    userAgent: navigator.userAgent,
    errorCategory: category,
    payload: payload ? JSON.stringify(payload) : null,
    ...Object.entries(context)
      .reduce((acc, [key, value]) => (value != null ? { ...acc, [key]: value } : acc), {})
  };

  // Create error event with better title for grouping
  const errorEvent = new Error(getErrorTitle(error, type, category));
  errorEvent.stack = error?.stack;

  // Skip certain errors in production
  const ignoredErrors = process.env.REACT_APP_SENTRY_DEBUG_ENABLED === "true"
    ? [403, 401]
    : [404, 403, 401];

  if (ignoredErrors.includes(error?.response?.status)) {
    return;
  }

  try {
    // Set user context for candidates
    if (type === "candidate" && (userId || email)) {
      Sentry.configureScope(scope => {
        scope.setUser({ id: userId, email });
      });
    }

    Sentry.withScope(scope => {
      // Set error category and type tags
      scope.setTag("error_category", category);
      scope.setTag("error_type", type);

      // Set common tags
      if (userId) scope.setTag("userId", userId);
      if (questionId) scope.setTag("questionId", questionId);
      if (jobId) scope.setTag("jobId", jobId);
      if (error?.response?.status) scope.setTag("status_code", error.response.status);

      // Add request context if available
      if (error.response || error.request) {
        scope.setContext("request", {
          url: error.config?.url,
          method: error.config?.method,
          status: error.response?.status,
          endpoint: getEndpointPattern(error.config?.url)
        });
      }

      // Add business context
      if (userId || questionId || jobId || email || answerId || info) {
        scope.setContext(type, {
          userId,
          questionId,
          jobId,
          answerId,
          email,
          info
        });
      }

      // Add error details context
      scope.setContext("error_details", {
        name: error?.name,
        message: error?.message,
        category,
        status: error?.response?.status,
        ...{ ...error.response, data: error?.response?.data }
      });

      // Add extra debugging context
      scope.setContext("extra", extraContext);

      // Set fingerprint for error grouping
      scope.setFingerprint(getErrorFingerprint(error, type, category));

      console.log("Sending error to Sentry:", errorEvent);

      // Use captureException for error objects and captureEvent for custom events
      if (error instanceof Error || error?.response) {
        Sentry.captureException(errorEvent);
      } else {
        // For custom events, create a structured event
        Sentry.captureEvent({
          message: errorEvent.message,
          level: "error",
          extra: extraContext,
          tags: {
            error_category: category,
            error_type: type,
            status_code: error?.response?.status
          }
        });
      }
    });
  } catch (e) {
    // Fallback console logging if Sentry fails
    console.error("Failed to send to Sentry:", e);
    console.error("Original error:", error);
    console.error("Context:", extraContext);
  }

  // Console logging
  console.log(`[${category}] Error:`, {
    error,
    category,
    context: extraContext,
    endpoint: getEndpointPattern(error?.config?.url)
  });
};
