import Button from "components/Common/Button/Button.tsx";
import Pill from "components/Common/Pill/Pill.tsx";
import { AUDIO_TYPE, VIDEO_TYPE } from "configs/jobs/constants";
import React from "react";
import { FaMusic, FaPlay } from "react-icons/fa";
import { pluralize } from "utils/string";
import { CardHeaderMediaProps, CardProps } from "./types.ts";
import "./styles.scss";

const CardHeaderMedia = ({
  type,
  src,
  alt = "",
  duration,
  onMediaClick
} : CardHeaderMediaProps) => {
  const isPlayable = type === VIDEO_TYPE || type === AUDIO_TYPE;
  const containerClasses = `
    relative h-auto max-h-52 w-full overflow-hidden rounded-t-2xl bg-gray-100 
    ${isPlayable ? "cursor-pointer group" : ""}
  `;

  const handleClick = () => {
    if (isPlayable && onMediaClick) {
      onMediaClick();
    }
  };

  return (
    <div
      className={containerClasses}
      onClick={handleClick}
      onKeyDown={e => {
        if (e.key === "Enter" || e.key === " ") {
          handleClick();
        }
      }}
      role={isPlayable ? "button" : undefined}
      tabIndex={isPlayable ? 0 : undefined}
    >
      {/* Image Thumbnail */}
      <img
        src={typeof src === "object" ? src.default : src}
        alt={alt}
        className="w-full h-full object-cover"
      />

      {/* Overlay for playable media */}
      {isPlayable && (
        <div className="
          absolute inset-0 bg-black/30 opacity-0 group-hover:opacity-100
          transition-opacity duration-200 flex items-center justify-center
        "
        >
          <div className="bg-purple-600 p-4 rounded-full transform scale-90 group-hover:scale-100 transition-transform duration-200">
            {type === "video" ? (
              <>
                Watch
                <FaPlay size={24} className="text-white" />
              </>
            ) : (
              <FaMusic size={24} className="text-white" />
            )}
          </div>
        </div>
      )}

      {/* Duration badge */}
      {duration && (
        <div className="absolute bottom-2 right-2 flex items-center gap-2">
          <div className="bg-white text-main-link text-sm px-2 py-1 rounded-full flex items-center gap-1">
            {duration}
          </div>
        </div>
      )}
    </div>
  );
};

const BaseCard = ({
  title,
  description,
  headerMedia,
  tags = [],
  button,
  className = "",
  viewsAmount,
  author
}: CardProps) => (
  <div className={`bg-white rounded-2xl border-1 border-tertiary-grey-10 min-h-[28rem] flex flex-col card-media w-full md:max-w-80 ${className}`}>
    {headerMedia && <CardHeaderMedia {...headerMedia} />}

    <div
      className="flex flex-col flex-grow gap-6 min-h-40"
      style={{
        padding: 20
      }}
    >
      {/* Tags or Pills */}
      {tags.length > 0 && (
        <div className="flex flex-wrap gap-2 mb-4">
          {tags.map((tag, index) => (
            <Pill key={`${tag.title}-${String(index)}`} {...tag} />
          ))}
        </div>
      )}

      <div className="flex-grow gap-2 flex flex-col">
        <h3 className={title?.className || "font-poppins text-xl font-semibold text-gray-900 mb-2"}>{title?.text}</h3>
        {description ? (
          <p className={description?.className || "text-gray-600 text-wrap line-clamp-2"}>{description?.text}</p>
        ) : null}

        {viewsAmount ? (
          <p className="text-gray-600 text-wrap line-clamp-2">{`${viewsAmount} ${pluralize("view", viewsAmount)}`}</p>
        ) : null}

        {author?.name ? (
          <p className="text-gray-600 text-wrap line-clamp-2">{author?.name}</p>
        ) : null}
      </div>

      { button ? <Button {...button} color="primary" /> : null }
    </div>
  </div>
);

export default BaseCard;
