/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { BackIcon } from "mixins/svgIcons";
import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { isFunction } from "lodash";

const BackButton = ({ color, handleClick }: {color: string, handleClick?: () => void}) => {
  const { t } = useTranslation();

  return (
    <div
      className="question__back lg:mb-4 border-solid font-medium font-sm mt-4 lg:mt-0"
      onClick={() => {
        if (isFunction(handleClick)) return handleClick();
        window.history.go(-1);
      }}
      style={{
        borderWidth: 1.5,
        borderColor: color
      }}
    >
      <div>
        <BackIcon
          fill={color}
        />
      </div>
      <div
        className="ml-1 pr-4"
        style={{
          color
        }}
      >
        {t("button.previous")}
      </div>
    </div>
  );
};

export default BackButton;
