/* eslint-disable camelcase */
import { create } from "zustand";
import { store } from "store";
import { AuthorProps } from "components/Common/Card/Base/types.ts";
import { getRequestWithToken } from "../sagas/api";

export interface Article {
  key: string;
  author: AuthorProps;
  content_duration: string;
  image: string;
  open_in: string;
  title: string;
  type: string;
  url: string;
  views_amount: string;
}

type OBLandingStore = {
  articles: Article[];
  isLoading: boolean;
  error: string | null;
  getArticles: () => Promise<void>;
  resetArticles: () => void;
}

const initialState = {
  articles: [],
  isLoading: false,
  error: null
};

const useOBLandingStore = create<OBLandingStore>(set => ({
  ...initialState,
  getArticles: async () => {
    const { token } = store.getState()?.auth?.data ?? {};
    if (!token) return;

    try {
      set({ isLoading: true, articles: [] });

      const response = await getRequestWithToken({
        url: "/api/interview-templates/support-articles/",
        token
      }) as { data: Article[] };

      set({
        articles: response?.data ?? [],
        isLoading: false
      });
    } catch (err: any) {
      console.error("Error fetching categories:", err);
      set({
        isLoading: false,
        error: err.message
      });
    }
  },
  resetArticles: () => set({ ...initialState })
}));

export default useOBLandingStore;
