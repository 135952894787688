import React from "react";
import { Link } from "react-router-dom";

export interface ButtonProps {
  text: string;
  onClick?: () => void;
  color: "default" | "primary" | "secondary";
  component?: React.ReactElement;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  to?: string; // For React Router Links
  link?: string; // For external links
}

export const getButtonStyles = (color: ButtonProps["color"] = "default") => {
  const baseStyles = "px-4 py-2 rounded-full font-medium transition-colors duration-200 flex items-center gap-2 w-fit leading-5 hover:no-underline font-poppins text-sm";

  const colorStyles = {
    default: "text-main-purple border-1 border-main-purple hover:bg-main-purple hover:text-white",
    primary: "bg-main-purple text-white hover:shadow-md hover:text-white",
    secondary: "bg-gray-100 text-gray-800 hover:bg-gray-200 hover:text-gray-800"
  };

  return `${baseStyles} ${colorStyles[color]}`;
};

const Button = ({
  text,
  link,
  onClick,
  color = "default",
  component,
  iconLeft,
  iconRight,
  to
} : ButtonProps) => {
  const buttonContent = (
    <>
      {iconLeft && iconLeft}
      {text}
      {iconRight && iconRight}
    </>
  );

  if (component) {
    return component;
  }

  if (to) {
    return (
      <Link to={to} className={getButtonStyles(color)}>
        {buttonContent}
      </Link>
    );
  }

  if (link) {
    return (
      <a
        href={link}
        className={getButtonStyles(color)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {buttonContent}
      </a>
    );
  }

  return (
    <button
      onClick={onClick}
      className={getButtonStyles(color)}
      type="button"
    >
      {buttonContent}
    </button>
  );
};


export default Button;
