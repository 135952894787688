import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ALL_IDV_REJECTED_REASON, DBS_RTW_TYPE, IDV_ABORTED, IDVStatusChip, RTW_COUNTRIES, RTW_TYPE } from "configs/jobs/constants";
import { connect } from "react-redux";

const RTWCheck = ({
  t,
  idvCheckData,
  currentQuestionAnswer
}) => {
  const isRTWCheck = (
    [RTW_TYPE, DBS_RTW_TYPE].includes(idvCheckData?.represent_as) ||
    [RTW_TYPE, DBS_RTW_TYPE].includes(idvCheckData?.check_type)
  ) && RTW_COUNTRIES.includes(currentQuestionAnswer?.question?.idv_country_of_employment_iso_code);

  const isCheckAborted = idvCheckData?.right_to_work?.status === IDV_ABORTED;

  if (!isRTWCheck) return null;

  return (
    <div className="candidateanswer__section__response-idv__response-item">
      <div className="candidateanswer__section__response-idv__response-item__row title">
        <div className="candidateanswer__section__response-idv__response-item-title">
          {t("job.candidate.overview.idv.rtw_check.title")}
        </div>
        {IDVStatusChip[idvCheckData?.right_to_work?.status]}
      </div>

      <div className="candidateanswer__section__response-idv__response-item__row">
        <p className="w-3/4">{`${t("job.candidate.overview.idv.answer.rtw.description")}`}</p>
      </div>

      {
        isCheckAborted ? (
          <div className="candidateanswer__section__response-idv__response-item__row">
            <p className="w-3/4">
              {ALL_IDV_REJECTED_REASON?.includes(idvCheckData?.right_to_work?.rejection_reason)
                ? t(`job.candidate.overview.idv.rejection_reason.${idvCheckData?.right_to_work?.rejection_reason}`)
                : idvCheckData?.dbs?.rejection_reason}
            </p>
          </div>
        ) : null
      }
    </div>
  );
};


const mapStateToProps = state => ({
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer
});

export default connect(mapStateToProps)(withTranslation()(RTWCheck));


RTWCheck.defaultProps = {
  idvCheckData: {},
  currentQuestionAnswer: {}
};

RTWCheck.propTypes = {
  t: PropTypes.func.isRequired,
  idvCheckData: PropTypes.shape({
    right_to_work: PropTypes.shape({
      status: PropTypes.string
    }),
    represent_as: PropTypes.string,
    check_type: PropTypes.string
  }),
  currentQuestionAnswer: PropTypes.shape({
    question: PropTypes.shape({
      idv_country_of_employment_iso_code: PropTypes.string
    })
  })
};
