/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { takeEvery, put, call, select } from "redux-saga/effects";
import { history } from "store";

import {
  isShareLinkSending,
  isPublicInfoLoading,
  shareCandidatePublic,
  shareCandidatePublicResponse,
  shareCandidatePublicError,
  shareCandidatesListPublic,
  shareCandidatesListPublicResponse,
  shareCandidatesListPublicError,
  fetchPublicUserInfo,
  fetchPublicUserInfoResponse,
  fetchPublicUserInfoError,
  fetchPublicBulkInfo,
  fetchPublicBulkInfoResponse,
  fetchPublicBulkInfoError,
  fetchPublicBulkCandidateInfo,
  setAvailableAnswer,
  setAvailableAnswerBulk,
  setAvailableLink,
  setAvailableAnswerResponse,
  setCandidateCompanyInfo
} from "store/modules/public/actions";

import { getToken } from "store/modules/auth/selectors";
import { getExcludedCandidates, getHasSelectAllStatus, getJobCurrent, getSelectedCandidates } from "store/modules/jobs/selectors";

import { isJobCandidateLoading } from "store/modules/jobs/actions";
import { getRequest, getRequestWithToken, patchRequestWithToken, postRequestWithToken } from "./api";

export function* shareCandidatePublicPageWorker({
  payload: { userId = "", jobId = "" }
}) {
  try {
    yield put(isShareLinkSending(true));

    const token = yield select(getToken);
    const response = yield call(getRequestWithToken, {
      url: `/api/job/candidate/${userId}/${jobId}/share/`,
      token
    });

    yield put(shareCandidatePublicResponse(response.data));
  } catch (error) {
    yield shareCandidatePublicError(error);
    console.log("[fethProfileRequestError]", error);
  } finally {
    yield put(isShareLinkSending(false));
  }
}

export function* shareCandidatesListPublicPageWorker({
  payload: { jobId = "" }
}) {
  const selectedCandidates = yield select(getSelectedCandidates);
  const selectAllStatus = yield select(getHasSelectAllStatus);
  const excludedCandidates = yield select(getExcludedCandidates);

  try {
    const token = yield select(getToken);
    const job = yield select(getJobCurrent);
    const response = yield call(postRequestWithToken, {
      url: "/api/job/candidates/bulk/showcase/create/",
      token,
      payload: {
        job: jobId,
        candidates: {
          received: selectAllStatus.received ? ["all"] : selectedCandidates.filter(a => a.status === "Received")?.map(a => a.id),
          invited: selectAllStatus.invited ? ["all"] : selectedCandidates.filter(a => a.status === "Invited")?.map(a => a.id),
          accepted: selectAllStatus.accepted ? ["all"] : selectedCandidates.filter(a => a.status === "Accepted")?.map(a => a.id),
          rejected: selectAllStatus.rejected ? ["all"] : selectedCandidates.filter(a => a.status === "Rejected")?.map(a => a.id),
          to_exclude: excludedCandidates?.map(a => a.id) || []
        },
        allowed_questions: job.questions.map(({ key }) => key)
      }
    });

    yield put(shareCandidatesListPublicResponse(response.data));
  } catch (error) {
    yield shareCandidatesListPublicError(error);
    console.log("[fethProfileRequestError]", error);
  }
}

export function* fetchPublicUserInfoWorker({ payload: { tokenId } }) {
  try {
    yield put(isJobCandidateLoading(false));
    yield put(isPublicInfoLoading(true));
    yield put(fetchPublicUserInfoResponse({}));
    const token = yield select(getToken);

    const response = yield call(token ? getRequestWithToken : getRequest, {
      url: `/api/candidates/public/${tokenId}/`,
      token
    });

    yield put(fetchPublicUserInfoResponse(response.data));
  } catch (error) {
    const {
      job_creator_email: jobCreatorEmail = "support@willotalent.com"
    } = error.response.data;

    // @TODO: BE needs to change the status code to 404
    if (error?.response?.status === 500) {
      return history.push("/page-not-found");
    }

    if (error.response.status === 404 || error.response.status === 429) {
      return history.push(`/common-not-found/${jobCreatorEmail}`);
    }

    if (error.response.status === 401) {
      const response = yield call(getRequest, {
        url: `/api/candidates/public/${tokenId}/`
      });

      yield put(fetchPublicUserInfoResponse(response.data));
    }

    yield fetchPublicUserInfoError(error);
    console.log("Failed to fetch public user info");
    console.log("[fethProfileRequestError]", error);
  } finally {
    yield put(isPublicInfoLoading(false));
  }
}

export function* fetchPublicBulkInfoWorker({ payload: { tokenId } }) {
  try {
    yield put(isJobCandidateLoading(false));
    yield put(isPublicInfoLoading(true));
    yield put(fetchPublicBulkInfoResponse([]));
    yield put(fetchPublicUserInfoResponse({}));
    yield put(setCandidateCompanyInfo({}));

    const response = yield call(getRequest, {
      url: `/api/candidates/public/bulk/${tokenId}/`
    });

    if (response?.data?.length === 0) window.location.href = `${window.location.origin}/public-candidates/error/not-found`;

    yield put(fetchPublicBulkInfoResponse(response.data));
  } catch (error) {
    const {
      job_creator_email: jobCreatorEmail = "support@willotalent.com"
    } = error.response.data;

    // @TODO: BE needs to change the status code to 404
    if (error?.response?.status === 500) {
      return history.push("/page-not-found");
    }

    if (error.response.status === 404 || error.response.status === 429) {
      return history.push(`/common-not-found/${jobCreatorEmail}`);
    }

    yield fetchPublicBulkInfoError(error);
  } finally {
    yield put(isPublicInfoLoading(false));
  }
}

export function* fetchPublicBulkCandidateInfoWorker({ payload: { bulkId, tokenId } }) {
  try {
    yield put(isJobCandidateLoading(false));
    yield put(isPublicInfoLoading(true));
    yield put(fetchPublicUserInfoResponse({}));
    const token = yield select(getToken);

    const response = yield call(token ? getRequestWithToken : getRequest, {
      url: `/api/candidates/public/bulk/${bulkId}/${tokenId}/`,
      token
    });

    yield put(fetchPublicUserInfoResponse(response.data));
    yield put(setCandidateCompanyInfo(response.data?.company));
  } catch (error) {
    const {
      job_creator_email: jobCreatorEmail = "support@willotalent.com"
    } = error.response.data;

    // @TODO: BE needs to change the status code to 404
    if (error?.response?.status === 500) {
      return history.push("/page-not-found");
    }

    if (error.response.status === 404 || error.response.status === 429) {
      return history.push(`/common-not-found/${jobCreatorEmail}`);
    }

    yield fetchPublicUserInfoError(error);
    console.log("[fethProfileRequestError]", error);
  } finally {
    yield put(isPublicInfoLoading(false));
  }
}

export function* setAvailableAnswerWorker({ payload: {
  available,
  answerId,
  jobId,
  userId
} }) {
  try {
    const token = yield select(getToken);

    const response = yield patchRequestWithToken({
      url: `/api/questions/job/${jobId}/interviewer/${userId}/${answerId}/`,
      token,
      payload: {
        is_showcase_available: available
      }
    });

    yield put(setAvailableAnswerResponse({ answerId, ...response.data }));
  } catch (error) {
    console.log("[setAvailableAnswerWorker]", error);
  }
}

export function* setAvailableAnswerBulkWorker({ payload: {
  bulkKey,
  isActive,
  questions,
  candidates
} }) {
  try {
    const token = yield select(getToken);

    yield patchRequestWithToken({
      url: `/api/job/candidates/bulk/showcase/${bulkKey}/update/`,
      token,
      payload: {
        is_active: isActive,
        allowed_questions: questions,
        candidates
      }
    });
  } catch (error) {
    console.log("[setAvailableAnswerWorker]", error);
  }
}

export function* setAvailableLinkWorker({ payload: {
  available,
  jobId,
  userId
} }) {
  try {
    const token = yield select(getToken);

    const response = yield patchRequestWithToken({
      url: `/api/job/candidate/${userId}/${jobId}/share/`,
      token,
      payload: {
        is_showcase_link_enabled: available
      }
    });

    yield put(shareCandidatePublicResponse(response.data));
  } catch (error) {
    console.log("[setAvailableLinkWorker]", error);
  }
}

export function* publicWatcher() {
  yield takeEvery(shareCandidatePublic, shareCandidatePublicPageWorker);
  yield takeEvery(shareCandidatesListPublic, shareCandidatesListPublicPageWorker);
  yield takeEvery(fetchPublicUserInfo, fetchPublicUserInfoWorker);
  yield takeEvery(fetchPublicBulkInfo, fetchPublicBulkInfoWorker);
  yield takeEvery(fetchPublicBulkCandidateInfo, fetchPublicBulkCandidateInfoWorker);
  yield takeEvery(setAvailableAnswer, setAvailableAnswerWorker);
  yield takeEvery(setAvailableAnswerBulk, setAvailableAnswerBulkWorker);
  yield takeEvery(setAvailableLink, setAvailableLinkWorker);
}

export default publicWatcher;
