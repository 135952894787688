import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  clearSetupState,
  clearQuestionsState,
  clearPreviewState,
  clearStatuses,
  clearErrors,
  clearSuccessMessage
} from "store/modules/jobs/actions";
import { clearErrors as clearBillingError } from "store/modules/profile/actions";
import { setModal } from "store/modules/modals/actions";
import { getCardInfoLoading } from "store/modules/profile/selectors";
import { getCurrentUser } from "store/modules/users/selectors";
import {
  getJobsData,
  getJobsSuccess,
  getJobsError,
  getJobsFilters,
  getJobsFiltersLoading,
  getIsJobsLoading
} from "store/modules/jobs/selectors";
import { getCompainesTableData } from "store/modules/companies/selectors";
import { getIsTrialOrPaid } from "store/modules/notifications/selectors";
import Jobs from "views/Jobs";

const mapStateToProps = state => {
  const { is_owner: isAccountOwner } = getCurrentUser(state);

  return {
    isAccountOwner,
    isCardInfoLoading: getCardInfoLoading(state),
    isSubscriptionActive: getIsTrialOrPaid(state),
    dataJobs: getJobsData(state),
    dataCompanies: getCompainesTableData(state),
    owners: state.companies.owners || [],
    isOwnersLoading: state.companies.isOwnersLoading,
    success: getJobsSuccess(state),
    error: getJobsError(state),
    currentUser: getCurrentUser(state),
    filters: getJobsFilters(state),
    isLoadingFilters: getJobsFiltersLoading(state),
    isJobsLoading: getIsJobsLoading(state),
    onboarding: state.dashboard?.onboarding
  };
};

const mapDispatchToProps = {
  setModal,
  clearBillingError,
  clearSetupState,
  clearQuestionsState,
  clearPreviewState,
  clearStatuses,
  clearErrors,
  clearSuccessMessage
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Jobs));
